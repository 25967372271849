import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Gallery from '../pages/gallery/Gallery';
import GalleryNewContent from '../pages/gallery/GalleryNewContent';
import Post from '../pages/post/Post';
import Newsletter from '../pages/newsletter/Newsletter';
import EditPost from '../pages/post/EditPost';
import Tag from '../pages/tags/Tag';
import EditTag from '../pages/tags/EditTag';
import Declaration from '../pages/declaration/Declaration';
import EditDeclaration from '../pages/declaration/EditDeclaration';
// import Languages from '../pages/languages/Languages';
import DraftPost from '../pages/post/DraftPost';
import ImportData from '../pages/importData/ImportData';
import Pages from '../pages/page/Page';
import EditPages from '../pages/page/EditPage';
// import Invoices from '../pages/invoices/Invoice';
import Orders from '../pages/orders/Orders';
import EditOrder from '../pages/orders/EditOrders';
import Refunds from '../pages/refund/Refunds';
import EditRefund from '../pages/refund/EditRefund';
import CreateNewInvoice from '../pages/e-invoices/CreateNewInvoice';
import OutgoingInvoices from '../pages/e-invoices/OutgoingInvoices';
import IncomingInvoices from '../pages/e-invoices/IncomingInvoices';
import Archive from '../pages/e-invoices/Archive';
import InvoiceDetail from '../pages/e-invoices/InvoiceDetail';
import EditInvoice from '../pages/e-invoices/EditInvoice';
import CreateNewRegularInvoice from '../pages/regular-invoices/CreateNewRegularInvoice';
import OutgoingRegularInvoices from '../pages/regular-invoices/OutgoingRegularInvoices';
import EditRegularInvoice from '../pages/regular-invoices/EditRegularInvoice';
import ArchiveRegularInvoice from '../pages/regular-invoices/ArchiveRegularInvoice';
import RegularClients from '../pages/clients/RegularClients';
import EditRegularClient from '../pages/clients/EditRegularClient';

const routes = [
  {
    label: 'Korisnici',
    children: [
      {
        label: 'Svi korisnici',
        path: '/admin/users',
        component: Users,
        allowed: ['admin'],
        showInMenu: 'admin',
      },
      {
        label: 'New User',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit User',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  //View user stoji ovde samo kao ruta koja omogucava da se klikom na ikonicu profil redirectuje na profil
  //Ne dirati
  // {
  //   label: 'Gallery',
  //   allowed: ['admin'],
  //   children: [
  //     {
  //       label: 'Gallery',
  //       path: '/admin/gallery',
  //       component: Gallery,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New Content',
  //       path: '/admin/gallery/new-content',
  //       component: GalleryNewContent,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'Data',
  //   children: [
  //     {
  //       label: 'All Data',
  //       path: '/admin/data',
  //       component: Data,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New Data',
  //       path: '/admin/new-data',
  //       component: EditData,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit Data',
  //       path: '/admin/edit-data/:dataId',
  //       component: EditData,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Categories',
  //       path: '/admin/data-categories',
  //       component: Categories,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Add/Edit Category',
  //       path: '/admin/data-categories/:categoryId',
  //       component: Categories,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Attributes',
  //       path: '/admin/data-attributes',
  //       component: Attributes,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'Post',
  //   children: [
  //     {
  //       label: 'All posts',
  //       path: '/admin/posts',
  //       component: Post,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New post',
  //       path: '/admin/new-post',
  //       component: EditPost,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit post',
  //       path: '/admin/edit-post/:postId',
  //       component: EditPost,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'All DraftPosts',
  //       path: '/admin/draft-post/:postId',
  //       component: DraftPost,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'All tags',
  //       path: '/admin/tags',
  //       component: Tag,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New tag',
  //       path: '/admin/new-tag',
  //       component: EditTag,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Edit tag',
  //       path: '/admin/edit-tag/:tagId',
  //       component: EditTag,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },

  // {
  //   label: 'Newsletter',
  //   children: [
  //     {
  //       label: 'All newsletter',
  //       path: '/admin/newsletter',
  //       component: Newsletter,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'Pages',
  //   children: [
  //     {
  //       label: 'All pages',
  //       path: '/admin/Pages',
  //       component: Pages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New page',
  //       path: '/admin/new-page',
  //       component: EditPages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit page',
  //       path: '/admin/edit-page/:id',
  //       component: EditPages,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Orders',
  //   children: [
  //     {
  //       label: 'All orders',
  //       path: '/admin/orders',
  //       component: Orders,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit order',
  //       path: '/admin/edit-order/:orderId',
  //       component: EditOrder,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Invoices',
  //   children: [
  //     {
  //       label: 'All invoices',
  //       path: '/admin/invoices',
  //       component: Invoices,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'Refunds',
  //   children: [
  //     {
  //       label: 'All refunds',
  //       path: '/admin/refunds',
  //       component: Refunds,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit order',
  //       path: '/admin/edit-refunds/:refundId',
  //       component: EditRefund,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Declarations',
  //   children: [
  //     {
  //       label: 'All declarations',
  //       path: '/admin/declarations',
  //       component: Declaration,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New declaration',
  //       path: '/admin/new-declaration',
  //       component: EditDeclaration,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit post',
  //       path: '/admin/edit-declaration/:declarationId',
  //       component: EditDeclaration,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Import podataka',
  //   children: [
  //     {
  //       label: 'Import podataka',
  //       path: '/admin/ImportData',
  //       component: ImportData,
  //       allowed: ['admin', 'user'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  {
    label: 'Klijenti',
    children: [
      {
        label: 'Sve kompanije',
        path: '/admin/regular-clients',
        component: RegularClients,
        allowed: ['admin', 'user'],
        showInMenu: true,
      },
      {
        label: 'Kreiranje kompanije',
        path: '/admin/new-regular-client',
        component: EditRegularClient,
        allowed: ['admin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Izmena kompanije',
        path: '/admin/edit-regular-client/:dataId',
        component: EditRegularClient,
        allowed: ['admin', 'user'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'Fakture',
  //   children: [
  //     {
  //       label: 'Kreiranje nove fakture',
  //       path: '/admin/create-new-regular-invoice',
  //       component: CreateNewRegularInvoice,
  //       allowed: ['admin', 'user'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Poslate fakture',
  //       path: '/admin/sent-invoices',
  //       component: OutgoingRegularInvoices,
  //       allowed: ['admin', 'user'],
  //       showInMenu: true,
  //     },
  //     // {
  //     //   label: 'Dospele fakture',
  //     //   path: '/admin/accounts-payable',
  //     //   component: IncomingInvoices,
  //     //   allowed: ['admin'],
  //     //   showInMenu: true,
  //     // },
  //     // {
  //     //   label: 'Detalji e-fakture',
  //     //   path: '/admin/sales-invoice-detail/:_id',
  //     //   component: InvoiceDetail,
  //     //   allowed: ['admin'],
  //     //   showInMenu: false,
  //     // },
  //     // {
  //     //   label: 'Detalji dospele e-fakture',
  //     //   path: '/admin/purchase-invoice-detail/:_id',
  //     //   component: InvoiceDetail,
  //     //   allowed: ['admin'],
  //     //   showInMenu: false,
  //     // },
  //     {
  //       label: 'Izmeni fakturu',
  //       path: '/admin/edit-regular-invoice/:_id',
  //       component: EditRegularInvoice,
  //       allowed: ['admin', 'user'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Arhiva',
  //       path: '/admin/archive-regular-invoice',
  //       component: ArchiveRegularInvoice,
  //       allowed: ['admin', 'user'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  {
    label: 'E-Fakture',
    children: [
      // {
      //   label: 'Kreiranje nove fakture',
      //   path: '/admin/create-new-invoice',
      //   component: CreateNewInvoice,
      //   allowed: ['admin', 'user'],
      //   showInMenu: true,
      // },
      {
        label: 'Poslate fakture',
        path: '/admin/accounts-receivable',
        component: OutgoingInvoices,
        allowed: ['admin', 'user'],
        showInMenu: true,
      },
      {
        label: 'Dospele fakture',
        path: '/admin/accounts-payable',
        component: IncomingInvoices,
        allowed: ['admin', 'user'],
        showInMenu: true,
      },
      {
        label: 'Detalji e-fakture',
        path: '/admin/sales-invoice-detail/:_id',
        component: InvoiceDetail,
        allowed: ['admin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Detalji dospele e-fakture',
        path: '/admin/purchase-invoice-detail/:_id',
        component: InvoiceDetail,
        allowed: ['admin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Izmeni e-fakturu',
        path: '/admin/edit-invoice/:_id',
        component: EditInvoice,
        allowed: ['admin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Arhiva',
        path: '/admin/archive',
        component: Archive,
        allowed: ['admin', 'user'],
        showInMenu: true,
      },
    ],
  },
  // {
  //   label: 'My profile',
  //   children: [
  //     {
  //       label: 'View',
  //       path: '/admin/view-user/:id',
  //       component: ViewUser,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },

  // {
  //   label: 'Languages',
  //   icon: TranslationOutlined,
  //   children: [
  //     {
  //       label: 'Languages',
  //       path: '/admin/languages',
  //       component: Languages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
];

export default routes;
