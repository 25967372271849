import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import { Input, Select, Form, Button, Row, Col, notification, Divider } from 'antd';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

const { Option } = Select;
const { Search } = Input;

const initialValues = {
  companyName: undefined,
  address: undefined,
  city: undefined,
  postalCode: undefined,
  country: undefined,
  phone: undefined,
  taxId: undefined,
  matBr: undefined,
  email: undefined,
  customerType: 'enterprise',
  customerNumber: undefined,
  customerCode: undefined,
  iban: undefined,
  bic: undefined,
};

let latinWord = [
  'NJ',
  'Nj',
  'nj',
  'N',
  'n',
  'Ć',
  'ć',
  'E',
  'e',
  'P',
  'R',
  'Dž',
  'DŽ',
  'dž',
  'Đ',
  'đ',
  'D',
  'd',
  'I',
  'A',
  'a',
  'B',
  'b',
  'Lj',
  'LJ',
  'lj',
  'J',
  'j',
  'L',
  'l',
  'K',
  'k',
  'Ž',
  'ž',
  'Z',
  'z',
  'I',
  'i',
  'U',
  'u',
  'R',
  'r',
  'V',
  'v',
  'G',
  'g',
  'C',
  'c',
  'H',
  'h',
  'Č',
  'č',
  'Š',
  'š',
  'T',
  't',
  'O',
  'o',
  'S',
  's',
  'P',
  'p',
  'F',
  'f',
  'M',
  'm',
];
let cyrillicWord = [
  'Њ',
  'Њ',
  'њ',
  'Н',
  'н',
  'Ћ',
  'ћ',
  'Е',
  'е',
  'П',
  'Р',
  'Џ',
  'Џ',
  'џ',
  'Ђ',
  'ђ',
  'Д',
  'д',
  'И',
  'А',
  'а',
  'Б',
  'б',
  'Љ',
  'Љ',
  'љ',
  'Ј',
  'ј',
  'Л',
  'л',
  'К',
  'к',
  'Ж',
  'ж',
  'З',
  'з',
  'И',
  'и',
  'У',
  'у',
  'Р',
  'р',
  'В',
  'в',
  'Г',
  'г',
  'Ц',
  'ц',
  'Х',
  'Х',
  'Ч',
  'ч',
  'Ш',
  'ш',
  'Т',
  'т',
  'О',
  'о',
  'С',
  'с',
  'П',
  'п',
  'Ф',
  'ф',
  'М',
  'м',
];

const CustomerInvoiceForm = ({
  setIsModalOpen,
  fetchCustomersData,
  SERVER_URL,
  data,
  setData,
  customerForm,
  allCompanies,
}) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const history = useHistory();

  useEffect(() => {
    if (data) {
      customerForm.setFieldsValue({ ...data });
    }
  }, [data, customerForm]);

  let customer = data ? data : initialValues;

  const convertLatinToCyrillic = (str) => {
    for (const index in latinWord) {
      let wordCyrillicIndex = cyrillicWord[index];
      str = str.replaceAll(new RegExp(latinWord[index], 'g'), wordCyrillicIndex);
    }
    return str;
  };

  const handleSearch = (value) => {
    const transformedInput = convertLatinToCyrillic(value.toLowerCase());
    let filteredCompanyData = [];
    if (value.length >= 3) {
      allCompanies?.filter((arr) => {
        if (arr.Name.toLowerCase().includes(value.toLowerCase()) || arr.Name.toLowerCase().includes(transformedInput)) {
          filteredCompanyData.push(arr);
        }
      });
      setFilteredCompanies(filteredCompanyData);
    }
  };

  const handleChange = (value) => {
    setSelectedCompany(JSON.parse(value));
  };

  useEffect(() => {
    if (selectedCompany && !data) {
      customerForm.setFieldsValue({
        companyName: selectedCompany.Name,
        taxId: `RS${selectedCompany.VatRegistrationCode}`,
        matBr: selectedCompany.BugetCompanyNumber,
      });
    }
  }, [selectedCompany]);

  const options = filteredCompanies.map((d, i) => (
    <Option key={i} value={JSON.stringify(d)}>
      <p>
        <b>{d.Name}</b>
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {d.VatRegistrationCode && <p>PIB: {d.VatRegistrationCode}</p>}
        {d.RegistrationCode && <p>Matični broj: {d.RegistrationCode} </p>}
      </div>
      <Divider />
    </Option>
  ));

  const createCompany = async (data) => {
    try {
      await Axios.post(
        `${SERVER_URL}/companies`,
        { ...data },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'Klijent je kreiran.',
        placement: 'bottomRight',
      });
      setIsModalOpen(false);
      setResult(true);
      fetchCustomersData();
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem with creating company. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      labelAlign='left'
      initialValues={customer}
      layout='horizontal'
      form={customerForm}
      onFinish={createCompany}
    >
      {/* <Search placeholder='Search by company name' onSearch={handleSearch} loading /> */}
      {!data && (
        <Select
          showSearch
          value={selectedCompany}
          placeholder='Pretraži kompaniju'
          style={{ width: '100%', marginBottom: '20px' }}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={allCompanies?.length === 0 ? 'Učitvanje kompanija. Molim sačekajte...' : 'Nema rezultata'}
          loading={true}
          allowClear={true}
        >
          {options}
        </Select>
      )}

      <Form.Item
        label='Naziv klijenta'
        name='companyName'
        rules={[
          {
            required: true,
            message: 'Molim Vas da unesete naziv klijenta!',
          },
        ]}
      >
        <Input placeholder='Naziv klijenta' />
      </Form.Item>

      <Form.Item
        name='customerType'
        label='Tip klijenta'
        rules={[
          {
            required: true,
            message: 'Molimo Vas da odaberete tip klijenta!',
          },
        ]}
      >
        <Select>
          <Option value='Enterprise'>Enterprise</Option>
          <Option value='Administration'>Administration</Option>
          <Option value='Residential'>Residential</Option>
        </Select>
      </Form.Item>

      <Form.Item name='customerNumber' label='Broj klijenta'>
        <Input placeholder='Broj klijenta će biti generisan' disabled />
      </Form.Item>
      <Form.Item name='customerCode' label='Kod klijenta'>
        <Input placeholder='Referenca klijenta u Vašoj bazi' />
      </Form.Item>

      <Form.Item
        label='Matični broj'
        name='matBr'
        rules={[
          {
            required: true,
            message: 'Molim Vas da unesete matični broj!',
          },
        ]}
      >
        <Input placeholder='Matični broj klijenta' />
      </Form.Item>

      <Form.Item
        label='Telefon'
        name='phone'
        rules={[
          {
            required: false,
            message: 'Molim Vas da unesete broj telefona!',
          },
        ]}
      >
        <Input placeholder='Broj telefona' />
      </Form.Item>

      <Form.Item label='Email' name='email'>
        <Input placeholder='Email' />
      </Form.Item>

      <Form.Item
        label='Poštanski broj'
        name='postalCode'
        rules={[
          {
            required: true,
            message: 'Molimo Vas da unesete poštanski broj!',
          },
        ]}
      >
        <Input placeholder='Poštanski broj klijenta' />
      </Form.Item>

      {/*<Form.Item label='Zemlja' name='drzava'>*/}
      {/*  <Input disabled={true} placeholder='Zemlja' />*/}
      {/*</Form.Item>*/}

      <Form.Item
        label='Grad'
        name='city'
        rules={[
          {
            required: true,
            message: 'Molimo Vas da unesete grad!',
          },
        ]}
      >
        <Input placeholder='Grad' />
      </Form.Item>

      <Form.Item
        label='Adresa'
        name='address'
        rules={[
          {
            required: true,
            message: 'Molim Vas da unesete adresu!',
          },
        ]}
      >
        <Input placeholder='Adresa klijenta' />
      </Form.Item>

      <Form.Item
        label='PIB'
        name='taxId'
        rules={[
          {
            required: true,
            message: 'Molimo Vas da unesete PIB!',
          },
        ]}
      >
        <Input placeholder='Poreski broj klijenta' />
      </Form.Item>

      <Form.Item label='Tekući račun' name='bankAccount'>
        <Input placeholder='Tekući račun' />
      </Form.Item>

      <Form.Item name='iban' label='IBAN'>
        <Input />
      </Form.Item>

      <Form.Item name='bic' label='BIC'>
        <Input />
      </Form.Item>

      {data && (
        <div className='text-right'>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </div>
      )}
    </Form>
    // </Modal>
  );
};

export default CustomerInvoiceForm;
