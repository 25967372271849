import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
// import { useHistory } from 'react-router-dom';
import { notification, Drawer /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserForm } from '../../components/forms';

const EditUserModal = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [companies, fetchCompanies] = useAxios('', null, currentuser.data.token, 'get');
  // const history = useHistory();
  const userId = props.userId;
  const { showModal, setShowModal } = props;

  useEffect(() => {
    if (userId) {
      fetchUser(`${SERVER_URL}/users/${userId}`, []);
    }
  }, [userId, fetchUser]);

  useEffect(() => {
    // if (userId) {
    fetchCompanies(`${SERVER_URL}/clients`, []);
    // }
  }, [fetchCompanies]);

  console.log('CLIENTS', companies);

  const updateUser = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/users/${userId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Ažurirano.',
        placement: 'bottomRight',
      });
      // history.push('/admin');
      // history.push('/admin/users');
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const createUser = async (data) => {
    if (data) {
      try {
        await Axios.post(`${SERVER_URL}/register-dashboard`, { ...data });
        notification.success({
          message: 'Korisnik je kreiran.',
          placement: 'bottomRight',
        });
        setResult(true);
      } catch (err) {
        console.log('err', err);
        notification.error({
          message:
            err.response.data && err.response.data.message
              ? err.response.data.message
              : 'Problem sa kreiranjem korisnika. Pokušajte kasnije.',
          placement: 'bottomRight',
        });
      }
    }
  };

  const onClose = () => {
    props.setShowModal(false);
  };

  return (
    <>
      {/* <div className={showModal ? 'overlay show' : 'overlay'} onClick={() => setShowModal(!showModal)} /> */}
      {/* <div className={showModal ? 'modal show' : 'modal'}> */}
      {/* <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>All users</Button>
        </Link>
      </div> */}

      <div style={{ textAlign: 'center', height: '100%' }}>
        {userId && user.isLoading && companies && (
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
        )}
        {userId && !user.isLoading && !user.isError && user.data && user.data.email && companies && (
          <Drawer title={'Izmeni korisnika'} placement='right' onClose={onClose} open={props.showModal} width={420}>
            <UserForm
              data={user.data}
              updateHandler={updateUser}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
              profile={props.profile}
              companies={companies}
            />
          </Drawer>
        )}
        {userId && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!userId && user && !user.data && (
          <Drawer title={'Dodaj korisnika'} placement='right' onClose={onClose} open={props.showModal} width={420}>
            <UserForm
              data={null}
              createHandler={createUser}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
              companies={companies}
            />
          </Drawer>
        )}
        {/* </div> */}
      </div>
    </>
  );
};

export default EditUserModal;
