import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import ArchiveTable from '../../components/tables/ArchiveRegularInvoiceTable';
import { notification, Tabs } from 'antd';
// import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import Axios from 'axios';

const { TabPane } = Tabs;

const PAYABLE_TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'user',
  'products',
  'documentType',
  'type',
  'userCompany',
  'uploadedFiles',
  'customerId',
  'customerType',
  'vats',
  'comment',
  'subtotal',
  'paymentReference',
  'customerReference',
  'supplierReference',
  'openBalance',
  'total',
  'discounts',
  'totalVatExcl',
  'totalDiscounts',
  'interestArrears',
  'prepaidAmount',
  'poNumber',
  'customerNumber',
  'salesInvoiceId',
  'currency',
  'paymentMethod',
  'pdf',
  'supplierAddress',
  'supplierVat',
  'supplierCity',
  'supplierCountryCode',
  'supplierCompanyNumber',
  'supplierPostalCode',
  'customer',
  'createdAt',
  'updatedAt',
  'customerName',
  'customerPostalAddress',
  'customerVat',
  'customerCompanyNumber',
  'documentCurrency',
  'customerZipCode',
  'customerCountry',
  'supplierBankAccount',
  'customerCity',
  'paymentID',
  'paymentMethod',
  'vatSelected',
  'invoiceId',
  'customerCountry',
  'supplierMail',
  'supplierReference',
  'supplierStreetName',
  'totalDiscounts',
  'comment',
  'products',
  'supplyDate',
  'paymentDate',
  'archived',
  'comment',
  'dueDate',
];

const Archive = () => {
  const currentuser = useContext(UserContext);
  const [payableInvoices, setPayableInvoices] = useState(undefined);

  useEffect(() => {
    getPurchaseInvoices();
  }, []);

  const getPurchaseInvoices = async () => {
    try {
      const data = await Axios.get(`${SERVER_URL}/invoices-archive`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setPayableInvoices(data.data);
    } catch (err) {
      console.log('error', err);
    }
  };

  const handleRecoverInvoice = async (id, type) => {
    try {
      await Axios.post(
        `${SERVER_URL}/invoices-archive-recovery/${id}`,
        { type: type },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'E-faktura je vraćena u pregled svih e-faktura.',
        placement: 'bottomRight',
      });
      await getPurchaseInvoices();
    } catch (err) {
      notification.error({
        message: 'Problem sa vraćanjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let payableColumnKeys;
  let payableNewColumnKeys;
  if (payableInvoices && payableInvoices?.length > 0) {
    const payableKeys = Object.keys(payableInvoices[0]);
    payableColumnKeys = payableKeys?.filter((k) => !PAYABLE_TABLE_COLUMN_KEYS.includes(k));
    let payableAlternativeNames = [];
    for (const payableColumnKey of payableColumnKeys) {
      if (payableColumnKey === 'purchaseInvoiceId') {
        payableAlternativeNames.push('ID fakture');
      } else if (payableColumnKey === 'documentDate') {
        payableAlternativeNames.push('datum dokumenta');
      } else if (payableColumnKey === 'supplierName') {
        payableAlternativeNames.push('Naziv kupca');
      } else if (payableColumnKey === 'documentNumber') {
        payableAlternativeNames.push('br. fakture');
      } else if (payableColumnKey === 'status') {
        payableAlternativeNames.push('status fakture');
      } else if (payableColumnKey === 'archivedDate') {
        payableAlternativeNames.push('datum arhiviranja');
      }
    }
    let keyPayableArray = payableColumnKeys;
    payableNewColumnKeys = payableColumnKeys?.map((item, index) => ({
      originalName: keyPayableArray[index],
      alternativeName: payableAlternativeNames[index],
    }));
  }

  return (
    <div style={{ padding: '8px' }}>
      <Tabs>
        <TabPane tab='Arhiva faktura' key={1}>
          <ArchiveTable
            columnKeys={payableNewColumnKeys}
            data={payableInvoices}
            recoverHandler={handleRecoverInvoice}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Archive;
