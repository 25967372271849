import React, { useEffect, useContext } from 'react';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import InvoiceForm from '../../components/forms/InvoiceForm';

const EditInvoice = (props) => {
  const currentuser = useContext(UserContext);
  const [invoiceData, fetchInvoiceData] = useAxios('', [], currentuser.data.token, 'get');
  const { _id } = props.match.params;
  useEffect(() => {
    let companyId = currentuser.data.id;
    fetchInvoiceData(`${SERVER_URL}/view-sales/${_id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
  }, []);

  return <InvoiceForm isNew={false} SERVER_URL={SERVER_URL} data={invoiceData} invoiceId={_id} />;
};

export default EditInvoice;
