import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from '../../App';
import { Input, Select, Button, Form, Card, DatePicker, Modal, Row, Col, notification, Upload, Divider } from 'antd';
import { UploadOutlined, DownloadOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import Axios from 'axios';
import FileSaver from 'file-saver';
import { toNumber } from 'lodash';
import CustomerInvoiceForm from './CustomerInvoiceForm';
import { useHistory } from 'react-router-dom';
import { Loading } from '../base/Loading';

const { Option } = Select;
const { TextArea } = Input;

const formInit = {
  documentType: 'invoice',
  documentNumber: undefined,
  documentLanguage: 'Serbian',
  documentCurrency: 'RSD',
  documentDate: moment(),
  supplyDate: undefined,
  dueDate: moment().add(1, 'M').subtract(1, 'd'),
  interestArrears: 0,
  poNumber: undefined,
  customerReference: undefined,
  supplierReference: undefined,
  paymentReference: undefined,
  comment: undefined,
  totalDiscounts: 0,
  totalVatExcl: 0,
  total: 0,
  prepaidAmount: 0,
  openBalance: 0,
  ratePrice: 0,
};

const InvoiceForm = ({ isNew, SERVER_URL, data, invoiceId }) => {
  const [form] = Form.useForm();
  const [customerForm] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedVat, setSelectedVat] = useState({});
  const [documentType, setDocumentType] = useState('invoice');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [buttonType, setButtonType] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [currencyType, setCurrencyType] = useState('RSD');
  const [languageType, setLanguageType] = useState('Serbian');
  const [measures, setMeasures] = useState();
  const [customers, setCustomers] = useState([]);
  const formRef = useRef(null);
  const saveBtnRef = useRef(null);
  const sendBtnRef = useRef(null);
  const history = useHistory();

  // conditional setting of form fields values if the page is in edit mode
  useEffect(() => {
    if (data && !isNew) {
      form.setFieldsValue(data.data);
      setSelectedCustomer(data.data.customer);
      // this is used for force setting of form details in edit mode.
      // this allows user to enter edit mode and to change nothing
      // after that all VAT data will remain the same in pdf
      onValuesChange(data.data, data.data);
    } else {
      setInvoiceData(formInit);
      form.setFieldsValue(formInit);
    }
  }, [form, data]);

  useEffect(() => {
    if (!data) {
      fetchAllCompanies();
    }
  }, []);

  const fetchAllCompanies = async () => {
    try {
      await Axios.get(`${SERVER_URL}/eCompanies`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((res) => {
        setAllCompanies(res.data);
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (data?.data) {
    data.data.documentDate = data.data.documentDate && moment(data.data.documentDate);
    data.data.supplyDate = data.data.supplyDate && moment(data.data.supplyDate);
    data.data.dueDate = data.data.dueDate && moment(data.data.dueDate);
  }

  const fetchCustomersData = async () => {
    try {
      await Axios.get(`${SERVER_URL}/customer`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((res) => {
        setCustomers(res?.data);
      });
    } catch (error) {
      notification.error({
        message: 'Cannot access customers. Check your company apiKey and try again',
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    fetchCustomersData();
  }, []);

  const fetchMeasuresData = async () => {
    try {
      await Axios.get(`${SERVER_URL}/measures`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((res) => {
        setMeasures(res?.data);
      });
    } catch (error) {
      notification.error({
        message: 'Cannot access measures. Check your company apiKey and try again',
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    fetchMeasuresData();
  }, []);

  // helper function for calculating values
  const helperFunction = (arr) => {
    let sum = 0;
    for (let index = 0; index < arr.length; index++) {
      sum += arr[index];
    }
    return sum;
  };

  // helper function for removing properties with 0 values
  const removeZero = (item) =>
    Object.keys(item)
      .filter((key) => item[key] !== 0)
      .reduce((newObj, key) => {
        newObj[key] = item[key];
        return newObj;
      }, {});

  const showUploadList = {
    showDownloadIcon: true,
    downloadIcon: <DownloadOutlined />,
    showRemoveIcon: true,
  };

  const onSearch = (value) => {
    // console.log('search:', value);
  };

  const onValuesChange = (value, allValues) => {
    let totalVatExclSum = 0;
    let totalSum = 0;
    let totalDiscountSum;

    const vats = [];

    let ratePriceKey = 'ratePrice';
    let discountKey = 'discount';
    let vatKey = 'vat';
    let quantityKey = 'quantity';

    /** start of setting invoiceRows form-list values **/
    const array = [];
    allValues.products?.map((item, index) => {
      let object = {
        description: '',
        discount: 0,
        grossPrice: 0,
        quantity: 1,
        ratePrice: 0,
        serviceProduct: '',
        subtotal: 0,
        unit: '',
        vat: 0,
      };

      if (item) {
        object.description = item.description;
        object.discount = toNumber(item.discount);
        object.quantity = toNumber(item.quantity);
        object.ratePrice = toNumber(item.ratePrice);
        object.serviceProduct = item.serviceProduct;
        object.unit = item.unit;
        object.vat = toNumber(item.vat);

        const subtotalCalc =
          (toNumber(item[quantityKey]) *
            (toNumber(item[ratePriceKey]) - (toNumber(item[ratePriceKey]) * toNumber(item[discountKey])) / 100) *
            (100 + toNumber(item[vatKey]))) /
          100;

        object.subtotal = subtotalCalc.toFixed(2);

        const grossPriceCalc =
          toNumber(item[quantityKey]) *
          (toNumber(item[ratePriceKey]) - (toNumber(item[ratePriceKey]) * toNumber(item[discountKey])) / 100);

        object.grossPrice = grossPriceCalc.toFixed(2);

        // add corresponding vats
        const obj = {};
        obj[item.vat] = subtotalCalc - grossPriceCalc;

        vats.push(obj);
        array.push(object);
      } else {
        array.push(object);
      }

      const filteredArray = [];
      array.forEach((element) => {
        if (element.vat !== undefined && element.serviceProduct !== undefined) {
          filteredArray.push(element);
        }
      });

      form.setFieldsValue({ ['products']: filteredArray });
    });

    // sum all vat values per key
    let total10 = 0;
    let total15 = 0;
    let total20 = 0;
    vats.map((item) => {
      if (item) {
        if (item[10]) {
          total10 += item[10];
        }
        if (item[15]) {
          total15 += item[15];
        }
        if (item[20]) {
          total20 += item[20];
        }
      }
    });

    let vatsByTaxRateObject = {
      10: total10,
      15: total15,
      20: total20,
    };

    // remove object properties with 0 values in purpose of not showing those VAT fields
    vatsByTaxRateObject = removeZero(vatsByTaxRateObject);

    /** start of setting fields with total values in invoice **/
    const formArray = form.getFieldsValue();
    const discountValues = [];
    const totalVatExcluded = [];
    const totalSumArray = [];
    formArray.products?.forEach((element) => {
      if (element.vat !== undefined && element.serviceProduct !== undefined) {
        discountValues.push(
          toNumber(element[quantityKey]) * ((toNumber(element[ratePriceKey]) * toNumber(element[discountKey])) / 100),
        );
        totalVatExcluded.push(
          toNumber(element[quantityKey]) *
            (toNumber(element[ratePriceKey]) -
              (toNumber(element[ratePriceKey]) * toNumber(element[discountKey])) / 100),
        );
        totalSumArray.push(
          element[quantityKey] *
            (element[ratePriceKey] -
              (toNumber(element[ratePriceKey]) * toNumber(element[discountKey])) / 100 +
              ((toNumber(element[ratePriceKey]) -
                (toNumber(element[ratePriceKey]) * toNumber(element[discountKey])) / 100) *
                element[vatKey]) /
                100),
        );
      }
    });

    totalDiscountSum = helperFunction(discountValues);
    totalVatExclSum = helperFunction(totalVatExcluded);
    totalSum = helperFunction(totalSumArray);
    /** end of setting fields with total values in invoice **/

    setSelectedVat(vatsByTaxRateObject);
    for (const key in vatsByTaxRateObject) {
      if (vatsByTaxRateObject.hasOwnProperty(key)) {
        const vatSelectedKey = `vatSelected__${key}`;
        form.setFieldsValue({ [vatSelectedKey]: vatsByTaxRateObject[key].toFixed(2) });
      }
    }

    if (!('openBalance' in value)) {
      let prepaidAmount = allValues.prepaidAmount;
      formRef.current.setFieldsValue({
        totalDiscounts: totalDiscountSum.toFixed(2),
        totalVatExcl: totalVatExclSum.toFixed(2),
        total: totalSum.toFixed(2),
        openBalance: (totalSum - prepaidAmount).toFixed(2),
      });
    } else {
      let openBalance = allValues.openBalance;
      formRef.current.setFieldsValue({
        totalDiscounts: totalDiscountSum.toFixed(2),
        totalVatExcl: totalVatExclSum.toFixed(2),
        total: totalSum.toFixed(2),
        prepaidAmount: (totalSum - openBalance).toFixed(2),
      });
    }
  };

  const onFinish = async (values) => {
    const vats = selectedVat;
    const receiver = selectedCustomer;
    let uploadedFiles = [];
    let upload = [];
    for (let i = 0; i <= files.length - 1; i++) {
      uploadedFiles = files[i].info.file.response;
      upload.push(uploadedFiles);
    }

    // create PUT API call parameters if the page is in edit mode
    let APIRoute;
    const axiosMethod = data && buttonType === 'save' ? Axios.put : Axios.post;
    const editMode = data && buttonType === 'save';
    if (editMode) {
      APIRoute = `edit-sales/${invoiceId}`;
    } else {
      // create POST API call parameters if the page is not in edit mode
      APIRoute = buttonType === 'save' && !editMode ? `save-sales-invoice` : buttonType === 'send' ? `postUbl` : '';
    }
    values.vats = vats;
    values.customer = receiver;
    values.uploadedFiles = upload;
    values.userCompany = currentuser.data.id;
    values._id = invoiceId ? invoiceId : null;

    try {
      await axiosMethod(`${SERVER_URL}/${APIRoute}`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((r) => {
        notification.success({
          message: `${
            buttonType === 'save' ? `Faktura je sačuvana.` : buttonType === 'send' ? `Faktura je poslata.` : ''
          }`,
          placement: 'bottomRight',
        });
        history.push('/admin/accounts-receivable');
      });
    } catch (error) {
      if (error.response.data) {
        notification.error({
          message: error.response.data,
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: `${
            buttonType === 'save'
              ? `Problem sa čuvanjem fakture. Pokušajte malo kasnije.`
              : buttonType === 'send'
              ? `Problem sa slanjem fakture. Pokušajte malo kasnije.`
              : ''
          }`,
          placement: 'bottomRight',
        });
      }
    } finally {
      setButtonType();
    }
  };

  return (
    <Card title={isNew ? 'KREIRAJ NOVU E-FAKTURU' : 'IZMENI E-FAKTURU'} bordered={false} style={{ width: '100%' }}>
      <Form
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        labelAlign='left'
        onValuesChange={(value, allValues) => onValuesChange(value, allValues)}
        initialValues={invoiceData}
        onFinish={(values) => onFinish(values)}
        layout='horizontal'
        form={form}
        ref={formRef}
      >
        <Row justify='space-between'>
          <Col span={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Form.Item
              label='Tip dokumenta'
              name='documentType'
              rules={[
                {
                  required: true,
                  message: 'Please choose document type!',
                },
              ]}
            >
              <Select style={{ width: '300px' }} onChange={(value) => setDocumentType(value)}>
                <Option value='invoice'>Račun</Option>
                {/* TODO activate those options after full implementation */}
                {/*<Option value='credit note'>Credit Note</Option>*/}
                {/*<Option value='debit note'>Debit Note</Option>*/}
                <Option value='prepayment invoice'>Predračun</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label='Broj dokumenta'
              name='documentNumber'
              rules={[
                {
                  required: true,
                  message: 'Please enter document number!',
                },
              ]}
            >
              <Input style={{ width: '300px' }} />
            </Form.Item>
            <Form.Item
              label='Jezik'
              name='documentLanguage'
              rules={[
                {
                  required: true,
                  message: 'Molimo vas da izaberete jezik!',
                },
              ]}
            >
              <Select style={{ width: '300px' }} onChange={(value) => setLanguageType(value)}>
                <Option value='Serbian'>Serbian</Option>
                <Option value='English'>English</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label='Valuta'
              name='documentCurrency'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da izaberete valutu!',
                },
              ]}
            >
              <Select style={{ width: '300px' }} onChange={(value) => setCurrencyType(value)}>
                <Option value='RSD'>RSD</Option>
                <Option value='EUR'>EUR</Option>
                <Option value='USD'>USD</Option>
                <Option value='GBP'>GBP</Option>
                <Option value='CHF'>CHF</Option>
                <Option value='RUB'>RUB</Option>
              </Select>
            </Form.Item>
            {(documentType === 'credit note' || documentType === 'debit note') && (
              <Form.Item
                label='Original broj fakture'
                name='originalInvoiceNumber'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas da uneste originalni broj fakture!',
                  },
                ]}
              >
                <Input style={{ width: '300px' }} />
              </Form.Item>
            )}
          </Col>
          <Col span={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Form.Item
              label='Datum dokumenta'
              name='documentDate'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da izaberete datum dokumenta!',
                },
              ]}
            >
              <DatePicker
                format={languageType === 'Serbian' ? 'DD-MM-YYYY' : 'YYYY-MM-DD'}
                style={{ width: '300px' }}
              />
            </Form.Item>
            {documentType !== 'debit note' && (
              <Form.Item
                label='Datum isporuke'
                name='supplyDate'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas da izaberete datum isporuke!',
                  },
                ]}
              >
                <DatePicker
                  placeholder='Izabrati datum'
                  format={languageType === 'Serbian' ? 'DD-MM-YYYY' : 'YYYY-MM-DD'}
                  style={{ width: '300px' }}
                />
              </Form.Item>
            )}

            <Form.Item
              label='Rok'
              name='dueDate'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da izaberete krajnji rok!',
                },
              ]}
            >
              <DatePicker
                style={{ width: '300px' }}
                format={languageType === 'Serbian' ? 'DD-MM-YYYY' : 'YYYY-MM-DD'}
              />
            </Form.Item>

            {(documentType === 'credit note' || documentType === 'debit note') && (
              <Form.Item
                label='Original datum fakture'
                name='originalInvoiceDate'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas da izaberete originalni datum fakture!',
                  },
                ]}
              >
                <DatePicker style={{ width: '300px' }} format='DD-MM-YYYY' />
              </Form.Item>
            )}
            <Form.Item
              label='Kamata za kašnjenje'
              name='interestArrears'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete kamatu za kašnjenje!',
                },
              ]}
            >
              <Input style={{ width: '300px' }} suffix='% po danu' defaultValue={0} />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ backgroundColor: '#6c9cb450' }} />

        <Row style={{ marginBlock: '40px' }} justify='space-between'>
          <Col
            span={12}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '20px 0' }}
          >
            <h2>
              Od: <b>{currentuser?.data?.companyName}</b>
            </h2>
            <p>Adresa: {currentuser?.data?.address}</p>
            <p>
              Grad: {currentuser?.data?.city} {currentuser.data?.zip}
            </p>
            <p>
              <b>PIB: {currentuser?.data?.pib}</b>
            </p>
            <p>
              <b>Matični broj: {currentuser?.data?.companyNumber}</b>
            </p>
          </Col>

          <Col span={12} style={{ display: 'flex', flexDirection: 'column', margin: '20px 0' }}>
            {isNew ? (
              <>
                {' '}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2>Za:</h2>{' '}
                  <Select
                    style={{ width: '90%' }}
                    optionFilterProp='children'
                    onSearch={onSearch}
                    onChange={(value) => setSelectedCustomer(JSON.parse(value))}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {customers?.map((customer, index) => (
                      <Option key={index} value={JSON.stringify(customer)}>
                        {customer.companyName}
                      </Option>
                    ))}
                  </Select>
                </div>
                <Button
                  type='secondary'
                  onClick={showModal}
                  style={{ width: 'fit-content', alignSelf: 'end', margin: '20px 0' }}
                >
                  Dodaj novog klijenta
                </Button>
                {Object.keys(selectedCustomer).length > 0 && (
                  <>
                    <p>
                      <strong>{selectedCustomer.companyName}</strong>
                    </p>
                    <p>Adresa: {selectedCustomer.address}</p>
                    <p>
                      Grad: {selectedCustomer.city} {selectedCustomer.postalCode}
                    </p>
                    <p>
                      <b>PIB: {selectedCustomer.taxId}</b>
                    </p>
                    <p>
                      <b>Matični broj: {selectedCustomer.matBr}</b>
                    </p>
                  </>
                )}{' '}
              </>
            ) : (
              <>
                <p>
                  Za: <b>{data?.data?.customer?.companyName}</b>
                </p>
                <p>Adresa: {data?.data?.customer?.address}</p>
                <p>
                  Grad: {data?.data?.customer?.city} {data?.data.customer?.postalCode}
                </p>
                <p>
                  <b>PIB: {data?.data?.customer?.taxId}</b>
                </p>
                <p>
                  <b>Matični broj: {data?.data?.customer?.matBr}</b>
                </p>
              </>
            )}
          </Col>

          <Divider style={{ backgroundColor: '#6c9cb450' }} />
        </Row>
        <Row span={24} style={{ marginBlock: '40px' }}>
          <Col span={24}>
            <h2>
              <strong>Dodatni detalji</strong>
            </h2>
            <Form.Item label='PO broj' name='poNumber'>
              <Input />
            </Form.Item>
            <Form.Item label='Referenca ka kupcu' name='customerReference'>
              <Input />
            </Form.Item>
            <Form.Item label='Referenca ka dobavljaču' name='supplierReference'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.List
          name='products'
          initialValue={
            data?.data
              ? invoiceData?.products
              : [
                  {
                    description: '',
                    discount: 0,
                    grossPrice: 0,
                    quantity: 1,
                    ratePrice: 0,
                    serviceProduct: '',
                    subtotal: 0,
                    unit: '',
                    vat: 20,
                  },
                ]
          }
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={index} span={24}>
                  <Col span={3}>
                    <Form.Item
                      label='Proizvod'
                      name={[field.name, 'serviceProduct']}
                      className='invoice-product'
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: 'Molimo Vas unesite stavku fakture!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label='Opis'
                      name={[field.name, 'description']}
                      className='invoice-product'
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: 'Molimo Vas unesite opis stavke fakture!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      label='Količina'
                      initialValue={1}
                      name={[field.name, 'quantity']}
                      className='invoice-product'
                      colon={false}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item label='Jedinica' name={[field.name, 'unit']} className='invoice-product' colon={false}>
                      <Select>
                        {measures?.map((measure, index) => (
                          <Option key={index} value={measure?.NameEng}>
                            {measure?.NameEng}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label='Cena'
                      name={[field.name, 'ratePrice']}
                      initialValue={0}
                      className='invoice-product'
                      colon={false}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      label='Popust'
                      name={[field.name, 'discount']}
                      ratePrice
                      initialValue={0}
                      className='invoice-product'
                      colon={false}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      label='PDV'
                      name={[field.name, 'vat']}
                      initialValue={20}
                      className='invoice-product'
                      colon={false}
                    >
                      <Select>
                        <Option value={0}>0%</Option>
                        <Option value={10}>10%</Option>
                        <Option value={15}>15%</Option>
                        <Option value={20}>20%</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label='Bruto'
                      name={[field.name, 'grossPrice']}
                      initialValue={0}
                      className='invoice-product'
                      colon={false}
                    >
                      <Input suffix={currencyType} type='number' />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label='Ukupno'
                      name={[field.name, 'subtotal']}
                      initialValue={0}
                      className='invoice-product'
                      colon={false}
                    >
                      <Input suffix={currencyType} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Button
                      type='text'
                      style={{ border: 'none', background: 'transparent', boxShadow: 'none' }}
                      onClick={() => remove(field.name)}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button onClick={() => add()} style={{ marginBottom: '40px' }}>
                Dodaj novi red
              </Button>
            </>
          )}
        </Form.List>

        <Row span={24}>
          <Col span={10}>
            <Form.Item label='Popusti ukupno' name='totalDiscounts'>
              <Input disabled suffix={currencyType} />
            </Form.Item>
            <Form.Item label='Ukupno bez PDV-a' name='totalVatExcl'>
              <Input disabled suffix={currencyType} />
            </Form.Item>
            {selectedVat &&
              Object.keys(selectedVat).map((keyName, i) => (
                <Form.Item key={i} label={`PDV ${keyName}%`} name={`vatSelected__${keyName}`}>
                  <Input disabled suffix={currencyType} />
                </Form.Item>
              ))}
            <Form.Item label='Ukupno' name='total'>
              <Input disabled suffix={currencyType} />
            </Form.Item>
            <Form.Item label='U pretplati' name='prepaidAmount' style={{ marginTop: '40px' }}>
              <Input suffix={currencyType} type='number' />
            </Form.Item>
            <Form.Item label='Za plaćanje' name='openBalance'>
              <Input suffix={currencyType} type='number' />
            </Form.Item>
          </Col>
        </Row>
        <Row span={24} style={{ marginBlock: '20px' }} justify='space-between'>
          <Col span={10}>
            <h2>Bankovni podaci</h2>
            <p>{currentuser.data.company?.name}</p>
            <Form.Item label='Platna referenca' name='paymentReference'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h2>Komentari</h2>
            <Form.Item name='comment' className='invoice-comment'>
              <TextArea style={{ minWidth: '100%', minHeight: '100px' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row span={24} style={{ marginBlock: '50px' }} justify='space-between'>
          <Col span={11}>
            <h2>Prilozi</h2>
            <Upload
              fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
              listType='picture'
              showUploadList={showUploadList}
              action={`${SERVER_URL}/upload-file`}
              name='file'
              headers={{
                Authorization: `Bearer ${currentuser.data.token}`,
                uri: 'static/pdf/',
                timestamp: Date.now().toString(),
              }}
              onChange={(info) => {
                if (!info.file.status) {
                  setIsError(true);
                }
                if (info.file.status === 'done') {
                  setIsError(false);
                  setFiles([...files, { info }]);
                  for (const file of info.fileList) {
                    let nameArray = file.name.split('.');
                    switch (nameArray[nameArray.length - 1]) {
                      case 'pdf':
                        file.thumbUrl = '/pdf.png';
                        break;
                      case 'docx':
                        file.thumbUrl = '/docx.png';
                        break;
                      case 'png':
                        file.thumbUrl = '/png.png';
                        break;
                      case 'jpg':
                        file.thumbUrl = '/jpg.png';
                        break;
                      case 'xlsx':
                        file.thumbUrl = '/xlsx.png';
                        break;

                      default:
                        file.thumbUrl = '/other.png';
                        break;
                    }
                  }
                  notification.success({
                    message: 'Uspešno dodavanje',
                    placement: 'bottomRight',
                  });
                } else if (info.file.status === 'error') {
                  notification.error({
                    message: 'Neuspešno dodavanje',
                    placement: 'bottomRight',
                  });
                }
                setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
              }}
              onRemove={async (file) => {
                notification.info({
                  message: 'Fajl izbrisan!',
                  placement: 'bottomRight',
                });
                const urlParts = file?.name;
                await Axios.post(`${SERVER_URL}/remove-file/${urlParts}`, {
                  withCredentials: false,
                  invoiceId,
                });
              }}
              onDownload={async (file) => {
                notification.info({
                  message: 'Preuzimanje u procesu.',
                  placement: 'bottomRight',
                });
                const urlParts = file?.name;
                const response = await Axios.get(`${SERVER_URL}/download-file/${urlParts}`, {
                  withCredentials: false,
                });
                FileSaver.saveAs(response.config.url, file?.name);
              }}
            >
              <Button icon={<UploadOutlined />}>Dodaj prilog</Button>
            </Upload>
            {data?.data?.uploadedFiles?.length > 0 &&
              data?.data?.uploadedFiles?.map((file, index) => (
                <div
                  key={index}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}
                >
                  <p style={{ marginBottom: '0px' }}>{file?.originalname}</p>
                  <div>
                    <DownloadOutlined
                      style={{ paddingInline: '10px' }}
                      // onClick={() => download(file.info.file.response)}
                      onClick={async () => {
                        notification.info({
                          message: 'Skidanje u procesu.',
                          placement: 'bottomRight',
                        });
                        const urlParts = file?.originalname;
                        const response = await Axios.get(`${SERVER_URL}/download-file/${urlParts}`, {
                          withCredentials: false,
                        });
                        FileSaver.saveAs(response.config.url, file?.originalname);
                      }}
                    />
                    <DeleteOutlined
                      onClick={async () => {
                        const id = invoiceId;
                        notification.info({
                          message: 'Fajl izbrisan!',
                          placement: 'bottomRight',
                        });
                        const urlParts = file?.originalname;
                        const response = await Axios.post(`${SERVER_URL}/remove-file/${urlParts}`, {
                          withCredentials: false,
                          id,
                        });
                        window.location.reload();
                      }}
                    />
                  </div>
                </div>
              ))}
          </Col>
        </Row>
        <Button
          className='b2b-primary'
          htmlType='submit'
          style={{ marginRight: '10px' }}
          ref={saveBtnRef}
          onClick={() => setButtonType('save')}
        >
          Sačuvaj
        </Button>
        <Button
          className='b2b-primary'
          htmlType='submit'
          style={{ marginRight: '10px' }}
          ref={sendBtnRef}
          onClick={() => setButtonType('send')}
        >
          Sačuvaj i pošalji
        </Button>
        <Button className='b2b-primary'>Poništi</Button>
        <Modal
          title='Dodaj novog klijenta'
          width={1000}
          centered
          open={isModalOpen}
          onOk={customerForm.submit}
          onCancel={handleCancel}
          style={{ top: 20 }}
          okText='Potvrdi'
          cancelText='Poništi'
        >
          <CustomerInvoiceForm
            customerForm={customerForm}
            setIsModalOpen={setIsModalOpen}
            fetchCustomersData={fetchCustomersData}
            SERVER_URL={SERVER_URL}
            allCompanies={allCompanies}
          />
        </Modal>
      </Form>
    </Card>
  );
};
export default InvoiceForm;
