import React, { useEffect, useContext, useState } from 'react';
import { LoadingOutlined, SearchOutlined, SolutionOutlined } from '@ant-design/icons';
import { notification, Button, Input } from 'antd';
import { UserContext } from '../../App';
import Axios from 'axios';
import { ClientsRegularTable } from '../../components/tables';
import ExportExcel from '../../components/csv/ExportExcel';
import { SERVER_URL } from '../../config';
import EditClientModal from '../../components/modals/EditClientModal';

const columnKeys = ['companyName', 'city', 'phone', 'email', 'pib', 'companyNumber'];

const RegularClients = () => {
  const currentuser = useContext(UserContext);
  const [clients, setClients] = useState(undefined);
  const [showModal, setShowModal] = useState();
  const [search, setSearch] = useState();
  const [searchedClients, setSearchedClients] = useState();
  const [csvClients, setCsvClients] = useState(undefined);
  const [clientId, setClientId] = useState(undefined);

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    let newClientData = [];
    if (clients?.data?.items) {
      for (const client of clients.data.items) {
        if (client.phone) {
          client.phone = client.phone[0];
          newClientData.push(client);
        }
        if (client && client?.contactPerson && client?.contactPerson.length > 0) {
          console.log('ITEM', client);
          client.contactPerson = client.contactPerson[0].contactPerson;
          // console.log('item contact', item.contactPerson[0].contactPerson);
        }
      }
    }
    setCsvClients(newClientData);
  }, [clients]);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  //Moze da se pretrazuje po imenu, prezimenu, role, status, grad,email
  //Nalazi se odredjivanje toga u user.controllers.js u searchUsers

  useEffect(() => {
    if (search) {
      Axios.get(`${SERVER_URL}/clients/search/${search}`)
        .then((res) => {
          setSearchedClients(res.data.clients);
        })
        .catch((err) => console.log(err));
    } else {
      setSearchedClients([]);
    }
  }, [search, currentuser]);

  const fetchClients = async () => {
    try {
      const fetchedData = await Axios.get(`${SERVER_URL}/clients`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setClients(fetchedData);
    } catch (err) {
      console.log('ERROR fetch clients', err);
      notification.error({
        message: 'Problem sa bazom. Pokušajte kasnije',
        placement: 'bottomRight',
      });
    }
  };

  const deleteClientHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Kompanija je obrisana.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem kompanije. Pokušajte kasnije',
        placement: 'bottomRight',
      });
    }
  };

  let tableData = [];
  if (search) {
    if (searchedClients && searchedClients.length > 0) {
      tableData = searchedClients.map((item) => {
        if (Array.isArray(item.role)) item.role = item.role.join(', ');
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();

        return item;
      });
    }
  } else if (clients?.data?.items && clients?.data?.items?.length > 0) {
    tableData = clients.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();

      return item;
    });
  }

  return (
    <div className='table usersWrapper'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Input
          bordered={false}
          placeholder='Pretraga'
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        {/*<Link to='/admin/new-user'>*/}
        <div className='desna-strana'>
          <ExportExcel
            csvData={csvClients != null && csvClients.length >= 1 ? csvClients : []}
            fileName={`Kompanije export ` + new Date().toLocaleString()} // Ime xlsx fajla
            propsToRemove={['_id', '__v', 'createdAt', 'note', 'updatedAt', 'attachments']} // Opcija 1 - uklanjaju se polja koje ne treba da se exportuju
            // format="products" // Opcija 2 - custom formatiranje - treba po imenu format polja u exportExcel.js formatirati polja po zelji
            users={false}
          />
          <Button onClick={() => setShowModal(!showModal)} className='b2b-primary'>
            <SolutionOutlined /> Dodaj kompaniju
          </Button>
          {/*</Link>*/}
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        {clients?.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!clients?.isLoading && clients?.data?.items && clients?.data?.items?.length > 0 && (
          <ClientsRegularTable
            data={tableData}
            deleteHandler={deleteClientHandler}
            columnKeys={columnKeys}
            title='User'
            setClientId={setClientId}
            editPath='/admin/edit-regular-client/'
            // viewPath='/admin/view-user/'
          />
        )}
        {!clients?.isLoading && clients?.data?.items && clients?.data?.items?.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
      <EditClientModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default RegularClients;
