import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { SyncOutlined, LoadingOutlined } from '@ant-design/icons';

import { notification, Button, Typography, Spin, Space } from 'antd';
import { UserContext } from '../../App';
import Table from '../../components/tables/InvoiceTable';
import { SERVER_URL } from '../../config';

const { Text } = Typography;

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'user',
  'products',
  'documentType',
  'type',
  'userCompany',
  'uploadedFiles',
  'customerId',
  'customerType',
  'vats',
  'comment',
  'subtotal',
  'paymentReference',
  'customerReference',
  'supplierReference',
  'openBalance',
  // 'total',
  'discounts',
  'totalVatExcl',
  'totalDiscounts',
  'interestArrears',
  'prepaidAmount',
  'poNumber',
  'customerNumber',
  'salesInvoiceId',
  'currency',
  'paymentMethod',
  // 'documentNumber',
  'purchaseInvoiceId',
  'pdf',
  'supplierAddress',
  'supplierVat',
  'supplierCity',
  'supplierCountryCode',
  'supplierCompanyNumber',
  'supplierPostalCode',
  'customer',
  'createdAt',
  'updatedAt',
  'customerName',
  'customerPostalAddress',
  'customerVat',
  'customerCompanyNumber',
  'documentCurrency',
  'customerZipCode',
  'customerCountry',
  'supplierBankAccount',
  'customerCity',
  'paymentID',
  'paymentMethod',
  'vatSelected',
  'invoiceId',
  'customerCountry',
  'supplierMail',
  'supplierReference',
  'supplierStreetName',
  'totalDiscounts',
  'comment',
  'products',
  'supplyDate',
  'paymentDate',
  'status',
];

const IncomingInvoices = () => {
  const currentuser = useContext(UserContext);
  const [purchases, setPurchases] = useState(undefined);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isSpin, setIsSpin] = useState(false);

  useEffect(() => {
    getPurchaseInvoices(currentuser?.data?.company);
  }, [setPurchases, currentuser]);

  console.log('CURRENT USER', currentuser);

  const getPurchaseInvoices = async (companyId) => {
    const data = await Axios.get(`${SERVER_URL}/get-purchase`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
      params: {
        company: companyId,
      },
    });
    setPurchases(data);
    setIsLoading(false);
  };

  const deleteInvoice = async (id, status) => {
    try {
      if (status === 'Draft') {
        await Axios.delete(`${SERVER_URL}/delete-draft/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }
      notification.success({
        message: 'E-faktura je arhivirana.',
        placement: 'bottomRight',
      });
      history.push('/admin/accounts-payable');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const purchaseScript = async () => {
    setIsSpin(true);
    try {
      const response = await Axios.post(
        `${SERVER_URL}/purchase-script?company=${currentuser?.data?.company}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'Successfully sync. with SEF',
        placement: 'bottomRight',
      });
      setIsSpin(false);
      return response.data;
    } catch (error) {
      console.log('error', error.message);
    } finally {
      window.location.reload();
    }
  };

  const archiveInvoice = async (id) => {
    try {
      await Axios.post(
        `${SERVER_URL}/archive-purchase/${id}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'E-faktura je arhivirana.',
        placement: 'bottomRight',
      });
      await getPurchaseInvoices(currentuser?.data?.company);
    } catch (err) {
      notification.error({
        message: 'Problem sa arhiviranjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    } finally {
      window.location.reload();
    }
  };

  const acceptInvoice = async (id, comment) => {
    try {
      await Axios.post(`${SERVER_URL}/accept-purchase/${id}`, comment, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'E-faktura je uspešno prihvaćena.',
        placement: 'bottomRight',
      });
      await getPurchaseInvoices(currentuser?.data?.company);
    } catch (err) {
      notification.error({
        message: 'Problem sa prihvatanjem. Molimo pokušsajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const rejectInvoice = async (id, comment) => {
    try {
      await Axios.post(`${SERVER_URL}/reject-purchase/${id}`, comment, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Faktura uspešno odbijena.',
        placement: 'bottomRight',
      });
      await getPurchaseInvoices(currentuser?.data?.company);
    } catch (err) {
      notification.error({
        message: 'Problem sa odbijanjem fakture. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  const checkStatusPurchase = async (id) => {
    try {
      setIsLoading(true);
      await Axios.get(`${SERVER_URL}/purchase-status/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Status proveren!',
        placement: 'bottomRight',
        duration: 2,
      });
      setIsLoading(false);
      // await getPurchaseInvoices();
    } catch (error) {
      notification.error({
        message: 'Problem sa proverom statusa e-fakture. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    } finally {
      window.location.reload();
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (purchases?.data && purchases?.data?.length > 0) {
    const keys = Object.keys(purchases?.data[0]);
    columnKeys = keys?.filter((k) => !TABLE_COLUMN_KEYS.includes(k));

    let alternativeNames = {
      documentNumber: 'Invoice Number',
      supplierName: 'Supplier Name',
      documentDate: 'Invoice Date',
      dueDate: 'Due Date',
      total: 'Total balance (RSD)',
      status: 'Status',
    };

    newColumnKeys = [
      { originalName: 'documentNumber', alternativeName: alternativeNames.documentNumber },
      { originalName: 'documentDate', alternativeName: alternativeNames.documentDate },
      { originalName: 'dueDate', alternativeName: alternativeNames.dueDate },
      { originalName: 'supplierName', alternativeName: alternativeNames.supplierName },
      { originalName: 'total', alternativeName: alternativeNames.total },
      { originalName: 'status', alternativeName: alternativeNames.status },
    ];
  }

  let tableData = [];
  if (purchases?.data && purchases?.data.length > 0) {
    tableData = purchases?.data.map((item) => {
      if (item.status === 'Seen') {
        item.status = 'Pregledano';
      }
      if (item.status === 'Sent') {
        item.status = 'Poslato';
      }
      if (item.status === 'Approved') {
        item.status = <Text type='success'>Prihvaćeno</Text>;
      }
      if (item.status === 'Rejected') {
        item.status = <Text type='danger'>Odbijeno</Text>;
      }
      if (item.status === 'Storno') {
        item.status = 'Stornirano';
      }
      if (item.status === 'Canceled') {
        item.status = 'Otkazano';
      }
      const documentDate = new Date(item.documentDate).toLocaleDateString('sr-RS');
      const dueDate = new Date(item.dueDate).toLocaleDateString('sr-RS');
      return { ...item, documentDate: documentDate, dueDate: dueDate };
    });
  }

  return (
    <>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto, auto', height: '100%' }}>
          {
            <Space size='middle'>
              <Spin size='large' />
            </Space>
          }
        </div>
      )}

      {!isLoading && purchases?.data && purchases?.data?.length > 0 && (
        <div>
          <div className='actions-block'>
            <Button
              onClick={purchaseScript}
              className='b2b-primary'
              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
            >
              <SyncOutlined spin={isSpin} /> Sinhronizacija sa SEF
            </Button>
          </div>
          <Table
            columnKeys={newColumnKeys}
            data={tableData}
            deleteHandler={deleteInvoice}
            archiveHandler={archiveInvoice}
            acceptHandler={acceptInvoice}
            rejectHandler={rejectInvoice}
            purchaseHandler={checkStatusPurchase}
          />
        </div>
      )}
      {purchases?.data && purchases?.data?.length === 0 && (
        <>
          <div className='actions-block'>
            <Button
              onClick={purchaseScript}
              className='b2b-primary'
              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
            >
              <SyncOutlined spin={isSpin} /> Sinhronizacija sa SEF
            </Button>
          </div>
          <h2 style={{ textAlign: 'center' }}>Nema podataka</h2>
        </>
      )}
    </>
  );
};

export default IncomingInvoices;
