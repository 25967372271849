import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { useSelector, shallowEqual } from 'react-redux';

const ProtectedRoute = ({ component: Component, allowed, user, ...rest }) => {
  const userRoles =
    user && user.role?.sort((a, b) => (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1)).join('-');
  const allowedRoles = allowed.sort((a, b) => (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1)).join('-');
  let loginRole;
  if (user?.role?.includes('admin')) {
    const userRoleArray = userRoles.split('-');
    const allowedRoleArray = allowedRoles.split('-');
    loginRole = userRoleArray.some((role) => allowedRoleArray.includes(role));
  } else if (user?.role?.includes('user')) {
    const userRoleArray = userRoles.split('-');
    const allowedRoleArray = allowedRoles.split('-');
    loginRole = allowedRoleArray.some((role) => userRoleArray.includes(role));
  } else {
    loginRole = null;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user && loginRole ? ( // if signed in and user's role is allowed
          <Component {...props} /> // render component
        ) : (
          <Redirect to='/login' /> // else redirect
        )
      }
    />
  );
};

export default ProtectedRoute;
