import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Select, Result, Radio, notification, Modal } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  legalType: 'PRIVATE',
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  role: [],
};

const UserForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile,
  companies,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [legalType, setLegalType] = useState(data ? data.legalType : 'PRIVATE');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [promptName] = useState(); //izbrisano setPromptName
  const [deletePop, setDeletePop] = useState();
  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
    setShowModal(!showModal);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  console.log('COMPANIES', companies);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleLegalType = (e) => {
    setLegalType(e.target.value);
    form.setFieldsValue({ legalType: e.target.value });
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Korisnik je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin/users');
      setTimeout(() => {
        if (id === currentuser.data.id) {
          sessionStorage.removeItem('user');
          currentuser.setLoggedIn(false);
          history.push('/login');
        }
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  // const onClickHandler = () => {
  //   setShowModal(!showModal);
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 2000);
  // };    dodati onClickHandler dole (izbrisan na Add user button-u)

  return (
    <>
      <div className='dashboard'>
        {profile && (
          <div className='delete-user' onClick={() => setDeletePop(!deletePop)}>
            Obriši korisnika <DeleteOutlined />
          </div>
        )}
        <div className='panel panel-body'>
          {/* eslint-disable-next-line no-useless-concat */}
          {/* <Card title={`${isNew ? 'New' : 'Edit'} user`} bordered={false}> */}
          {/* <CloseOutlined className='x-btn' onClick={() => setShowModal(false)} /> */}
          {!result && (
            <Form
              {...layout}
              name='basic'
              initialValues={user}
              onFinish={(values) => onFinish(values, isNew)}
              onFinishFailed={onFinishFailed}
              layout='horizontal'
              form={form}
            >
              {/* <Form.Item className='upload-wrapper right' name='avatar'>
                <UploadBox
                  editHandler={avatarHandler}
                  deleteHandler={deleteAvatarHandler}
                  image={image}
                  index={0}
                  name='avatar'
                />
              </Form.Item> */}
              {/* <Form.Item name='legalType'>
                <Radio.Group onChange={handleLegalType}>
                  <Radio value='PRIVATE'>Osoba</Radio>
                  <Radio value='COMPANY'>Kompanija</Radio>
                </Radio.Group>
              </Form.Item> */}

              <Form.Item
                /*label='Email'*/
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Molimo unesite e-mail!',
                  },
                ]}
              >
                <Input placeholder='Email' />
              </Form.Item>

              <Form.Item
                /*label='First name'*/
                name='firstName'
                rules={[
                  {
                    required: true,
                    message: 'Molimo unesite ime!',
                  },
                ]}
              >
                <Input placeholder='Ime' />
              </Form.Item>

              <Form.Item
                /*label='Last name'*/
                name='lastName'
                rules={[
                  {
                    required: true,
                    message: 'Molimo unesite prezime!',
                  },
                ]}
              >
                <Input placeholder='Prezime' />
              </Form.Item>

              <Form.Item /*label='Phone'*/ name='phone'>
                <Input placeholder='Telefon' />
              </Form.Item>

              {/* <Form.Item
                
                name='city'
              >
                <Input placeholder='Grad' />
              </Form.Item>

              <Form.Item  name='address'>
                <Input placeholder='Adresa' />
              </Form.Item>

              <Form.Item  name='zip'>
                <Input placeholder='Poštanski kod' />
              </Form.Item> */}

              {/* {legalType === 'COMPANY' && (
                <>
                  <Form.Item
                    
                    name='companyName'
                  >
                    <Input placeholder='Naziv kompanije' />
                  </Form.Item>

                  <Form.Item
                   
                    name='taxId'
                  >
                    <Input placeholder='PIB' />
                  </Form.Item>
                </>
              )} */}

              {isNew && (
                <Form.Item
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo da unesete šifru!',
                    },
                  ]}
                >
                  <Input.Password placeholder='Šifra' />
                </Form.Item>
              )}

              {/* <Form.Item name='bankAccount'>
                <Input placeholder='Broj bankovnog računa' />
              </Form.Item>

              <Form.Item name='pib'>
                <Input placeholder='PIB' />
              </Form.Item>

              <Form.Item name='companyNumber'>
                <Input placeholder='Matični broj' />
              </Form.Item>

              <Form.Item name='companyName'>
                <Input placeholder='Naziv kompanije' />
              </Form.Item>

              <Form.Item name='apiKey'>
                <Input placeholder='API ključ' />
              </Form.Item> */}

              <Form.Item name='company'>
                <Select mode='single' placeholder='Izaberite kompaniju'>
                  {companies.data &&
                    companies.data.items &&
                    companies.data.items.length > 0 &&
                    companies.data.items.map((company) => (
                      <Select.Option value={company._id} key={company._id}>
                        {company.companyName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                /*label='Role'*/
                name='role'
                rules={[
                  {
                    required: true,
                    message: 'Molimo da izaberete rolu!',
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{ offset: [0, -110] }}
                  mode='multiple'
                  placeholder='Role'
                >
                  <Select.Option value='admin'>Admin</Select.Option>
                  <Select.Option value='user'>Korisnik</Select.Option>
                </Select>
              </Form.Item>

              {!isNew && (
                <Form.Item
                  name='status'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo da izaberete status!',
                    },
                  ]}
                >
                  <Select getPopupContainer={(trigger) => trigger.parentNode}>
                    <Select.Option value='ACTIVE'>AKTIVAN</Select.Option>
                    <Select.Option value='SUSPENDED'>SUSPENDOVAN</Select.Option>
                    {/*<Select.Option value='DELETED'>OBRISAN</Select.Option>*/}
                    <Select.Option value='WAITING_FOR_ACTIVATION'>ČEKA NA AKTIVACIJU</Select.Option>
                  </Select>
                </Form.Item>
              )}

              <Form.Item {...tailLayout}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                  <Button className='b2b-primary' htmlType='submit' style={{ float: 'right', width: '50%' }}>
                    {data ? 'Sačuvaj promene' : 'Dodaj korisnika'}
                  </Button>
                  <Button
                    className='b2b-secondary'
                    style={{ float: 'right', width: '50%', color: '#81c3eb' }}
                    onClick={() => setShowModal(!showModal)}
                  >
                    Poništi
                  </Button>
                </div>
              </Form.Item>
            </Form>
          )}
          {result && (
            <Result
              title='Korisnik je kreiran i poslat je verifikacioni email.'
              extra={
                <Button
                  type='primary'
                  key='console'
                  onClick={() => {
                    setResult(false);
                    window.location.reload();
                  }}
                >
                  Ok
                </Button>
              }
            />
          )}
          {modal.visible && (
            <GalleryModal
              className='gallery-modal'
              open={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
          <Modal
            className='deleteModal'
            centered={true}
            open={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteUserHandler(user._id)}
            okText='Da'
            cancelText='Ne'
          >
            <p>Da li ste sigurni da želite da uradite brisanje</p>
            {promptName && <p>{promptName.toUpperCase()}?</p>}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UserForm;
