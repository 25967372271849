import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification, Button, Typography, Spin, Space } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import Table from '../../components/tables/InvoiceTable';
import { SERVER_URL } from '../../config';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'type',
  'user',
  'products',
  'documentType',
  'userCompany',
  'uploadedFiles',
  'customerId',
  'supplyDate',
  'customerType',
  'vats',
  'comment',
  'subtotal',
  'paymentReference',
  'customerReference',
  'supplierReference',
  'openBalance',
  'customerVat',
  'discounts',
  'totalVatExcl',
  'totalDiscounts',
  'interestArrears',
  'prepaidAmount',
  'poNumber',
  'customerNumber',
  // 'documentNumber',
  'pdf',
  'purchaseInvoiceId',
  'salesInvoiceId',
  'documentLanguage',
  'documentCurrency',
  'customerCompanyNumber',
  'customerPostalAddress',
  'customerCity',
  'customerZipCode',
  'customerCountry',
  'customerCounty',
  'createdAt',
  'updatedAt',
  'customerMatBr',
  'archived',
  'archivedDate',
  'company',
  'invoiceId',
];

const OutgoingInvoices = () => {
  const currentuser = useContext(UserContext);
  const [invoices, setInvoices] = useState([]);
  const [key, setKey] = useState(undefined);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isSpin, setIsSpin] = useState(false);

  console.log('CURRENT USER', currentuser);

  useEffect(() => {
    fetchAllInvoices(currentuser.data.company);
  }, [currentuser]);

  const fetchAllInvoices = async (companyId) => {
    try {
      await Axios.get(`${SERVER_URL}/sales-invoice`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
        params: {
          company: companyId,
        },
      }).then((response) => {
        if (response && response.data) {
          setInvoices(response);
          setIsLoading(false);
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteInvoice = async (id, status, value) => {
    try {
      if (status === 'Draft') {
        await Axios.delete(`${SERVER_URL}/delete-draft/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        }).then((r) => {
          fetchAllInvoices(currentuser?.data?.company);
          notification.success({
            message: 'E-faktura je izbrisana.',
            placement: 'bottomRight',
          });
          history.push('/admin/accounts-receivable');
        });
      } else if (status === 'Sent') {
        await Axios.delete(`${SERVER_URL}/storn/${id}`, {
          withCredentials: false,
          data: {
            source: value,
          },
        }).then((r) => {
          fetchAllInvoices(currentuser?.data?.company);
          notification.success({
            message: 'E-faktura je izbrisana.',
            placement: 'bottomRight',
          });
          history.push('/admin/accounts-receivable');
        });
      }
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const openPdf = async (path) => {
    try {
      const resPdF = await Axios.get(`${SERVER_URL}/invoices/pdf?path=${path}`, {
        withCredentials: false,
        responseType: 'arraybuffer',
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const blob = new Blob([resPdF.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  };

  const checkStatusSales = async (id) => {
    try {
      setIsLoading(true);
      await Axios.get(`${SERVER_URL}/sales-status/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Status proveren!',
        placement: 'bottomRight',
      });
      setIsLoading(false);
      // fetchAllInvoices();
    } catch (error) {
      notification.error({
        message: 'Problem sa proverom statusa fakture. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    } finally {
      window.location.reload();
    }
  };

  const salesScript = async () => {
    setIsSpin(true);
    try {
      const response = await Axios.post(
        `${SERVER_URL}/sales-script?company=${currentuser.data.company}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      console.log('responseeeee', response);

      notification.success({
        message: 'Successfully sync. with SEF',
        placement: 'bottomRight',
      });
      setIsSpin(false);
      return response.data;
    } catch (error) {
      console.log('error', error.message);
    } finally {
      window.location.reload();
    }
  };

  const archiveInvoice = async (id) => {
    try {
      await Axios.post(`${SERVER_URL}/archive-sales/${id}`, null, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'E-faktura je arhivirana.',
        placement: 'bottomRight',
      });
      fetchAllInvoices(currentuser?.data?.company);
    } catch (err) {
      notification.error({
        message: 'Problem sa arhiviranjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (invoices?.data && invoices?.data?.length > 0) {
    const keys = Object.keys(invoices?.data[0]);
    columnKeys = keys?.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    let alternativeNames = [];

    console.log('aaaaaa', columnKeys);
    for (const columnKey of columnKeys) {
      if (columnKey === 'documentNumber') {
        alternativeNames.push('Invoice Number');
      } else if (columnKey === 'customerNumber') {
        alternativeNames.push('Customer Number');
      } else if (columnKey === 'customerName') {
        alternativeNames.push('Customer Name');
      } else if (columnKey === 'status') {
        alternativeNames.push('Status');
      } else if (columnKey === 'documentDate') {
        alternativeNames.push('Document Date');
      } else if (columnKey === 'dueDate') {
        alternativeNames.push('Due Date');
      } else if (columnKey === 'total') {
        alternativeNames.push('Total balance (RSD)');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys?.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index],
    }));
  }

  let tableData = [];
  if (invoices.data && invoices.data.length > 0) {
    tableData = invoices.data.map((item) => {
      if (item.status === 'Sent') {
        item.status = 'Poslato';
      }
      if (item.status === 'Approved') {
        item.status = <Text type='success'>Prihvaćeno</Text>;
      }
      if (item.status === 'Rejected') {
        item.status = <Text type='danger'>Odbijeno</Text>;
      }
      if (item.status === 'Storno') {
        item.status = 'Stornirano';
      }
      if (item.status === 'Canceled') {
        item.status = 'Otkazano';
      }
      const documentDate = new Date(item.documentDate).toLocaleDateString('sr-RS');
      const dueDate = new Date(item.dueDate).toLocaleDateString('sr-RS');
      return { ...item, documentDate: documentDate, dueDate: dueDate };
    });
  }

  return (
    <>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto, auto', height: '100%' }}>
          {
            <Space size='middle'>
              <Spin size='large' />
            </Space>
          }
        </div>
      )}
      {!isLoading && invoices.data && invoices.data.length > 0 && (
        <div>
          <div className='actions-block'>
            <Button
              className='b2b-primary'
              onClick={salesScript}
              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
            >
              <SyncOutlined spin={isSpin} /> Sinhronizacija sa SEF
            </Button>
          </div>
          <Table
            key={key}
            columnKeys={newColumnKeys}
            data={tableData}
            deleteHandler={deleteInvoice}
            archiveHandler={archiveInvoice}
            salesHandler={checkStatusSales}
            openPdf={openPdf}
            isIncoming={true}
          />
        </div>
      )}
      {!invoices.isLoading && invoices?.data && invoices?.data?.length === 0 && (
        <>
          <div className='actions-block'>
            <Button
              onClick={salesScript}
              className='b2b-primary'
              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
            >
              <SyncOutlined spin={isSpin} /> Sinhronizacija sa SEF
            </Button>
          </div>
          <h2 style={{ textAlign: 'center' }}>Nema podataka</h2>
        </>
      )}
    </>
  );
};

export default OutgoingInvoices;
