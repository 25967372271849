import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import ArchiveTable from '../../components/tables/ArchiveTable';
import { notification, Tabs } from 'antd';
// import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import Axios from 'axios';

const { TabPane } = Tabs;

const RECEIVABLE_TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'type',
  'user',
  'products',
  'documentType',
  'userCompany',
  'uploadedFiles',
  'customerId',
  'supplyDate',
  'customerType',
  'vats',
  'comment',
  'subtotal',
  'paymentReference',
  'customerReference',
  'supplierReference',
  'openBalance',
  'customerVat',
  'discounts',
  'totalVatExcl',
  'totalDiscounts',
  'interestArrears',
  'prepaidAmount',
  'poNumber',
  'customerNumber',
  'pdf',
  'purchaseInvoiceId',
  'salesInvoiceId',
  'documentLanguage',
  'documentCurrency',
  'customerCompanyNumber',
  'customerPostalAddress',
  'customerCity',
  'customerZipCode',
  'customerCountry',
  'customerCounty',
  'createdAt',
  'updatedAt',
  'customerMatBr',
  'archived',
  'archivedDate',
  'status',
  'dueDate',
  'paymentDate',
  'total',
  'supplierAddress',
  'supplierCity',
  'supplierPostalCode',
  'supplierCountryCode',
  'supplierVat',
  'supplierCompanyNumber',
  'supplierName',
];

const PAYABLE_TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'user',
  'products',
  'documentType',
  'type',
  'userCompany',
  'uploadedFiles',
  'customerId',
  'customerType',
  'vats',
  'comment',
  'subtotal',
  'paymentReference',
  'customerReference',
  'supplierReference',
  'openBalance',
  'total',
  'discounts',
  'totalVatExcl',
  'totalDiscounts',
  'interestArrears',
  'prepaidAmount',
  'poNumber',
  'customerNumber',
  'salesInvoiceId',
  'currency',
  'paymentMethod',
  'pdf',
  'supplierAddress',
  'supplierVat',
  'supplierCity',
  'supplierCountryCode',
  'supplierCompanyNumber',
  'supplierPostalCode',
  'customer',
  'createdAt',
  'updatedAt',
  'customerName',
  'customerPostalAddress',
  'customerVat',
  'customerCompanyNumber',
  'documentCurrency',
  'customerZipCode',
  'customerCountry',
  'supplierBankAccount',
  'customerCity',
  'paymentID',
  'paymentMethod',
  'vatSelected',
  'invoiceId',
  'customerCountry',
  'supplierMail',
  'supplierReference',
  'supplierStreetName',
  'totalDiscounts',
  'comment',
  'products',
  'supplyDate',
  'paymentDate',
  'archived',
  'comment',
  'dueDate',
  'archivedDate',
  'status',
];

const Archive = () => {
  const currentuser = useContext(UserContext);
  const [payableInvoices, setPayableInvoices] = useState(undefined);
  const [receivableInvoices, setReceivableInvoices] = useState(undefined);

  useEffect(() => {
    getPurchaseInvoices();
    getSalesInvoices();
  }, []);

  const getPurchaseInvoices = async () => {
    try {
      const data = await Axios.get(`${SERVER_URL}/get-archive-purchase`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setPayableInvoices(data.data);
    } catch (err) {
      console.log('error', err);
    }
  };

  const getSalesInvoices = async () => {
    try {
      const data = await Axios.get(`${SERVER_URL}/get-archive-sales`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setReceivableInvoices(data.data);
    } catch (err) {
      console.log('error', err);
    }
  };

  const handleRecoverInvoice = async (id, type) => {
    try {
      await Axios.post(
        `${SERVER_URL}/recover-archived/${id}`,
        { type: type },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'E-faktura je vraćena u pregled svih e-faktura.',
        placement: 'bottomRight',
      });
      if (type === 'purchase') {
        await getPurchaseInvoices();
      }

      if (type === 'sales') {
        await getSalesInvoices();
      }
    } catch (err) {
      notification.error({
        message: 'Problem sa vraćanjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let payableColumnKeys;
  let payableNewColumnKeys;
  if (payableInvoices && payableInvoices?.length > 0) {
    const payableKeys = Object.keys(payableInvoices[0]);
    payableColumnKeys = payableKeys?.filter((k) => !PAYABLE_TABLE_COLUMN_KEYS.includes(k));
    let payableAlternativeNames = [];
    for (const payableColumnKey of payableColumnKeys) {
      if (payableColumnKey === 'purchaseInvoiceId') {
        payableAlternativeNames.push('ID fakture');
      } else if (payableColumnKey === 'documentDate') {
        payableAlternativeNames.push('datum dokumenta');
      } else if (payableColumnKey === 'supplierName') {
        payableAlternativeNames.push('Naziv kupca');
      } else if (payableColumnKey === 'documentNumber') {
        payableAlternativeNames.push('br. fakture');
      } else if (payableColumnKey === 'status') {
        payableAlternativeNames.push('status fakture');
      } else if (payableColumnKey === 'archivedDate') {
        payableAlternativeNames.push('datum arhiviranja');
      }
    }
    let keyPayableArray = payableColumnKeys;
    payableNewColumnKeys = payableColumnKeys?.map((item, index) => ({
      originalName: keyPayableArray[index],
      alternativeName: payableAlternativeNames[index],
    }));
  }

  let receivableColumnKeys;
  let receivableNewColumnKeys;
  if (receivableInvoices && receivableInvoices?.length > 0) {
    const receivableKeys = Object.keys(receivableInvoices[0]);
    receivableColumnKeys = receivableKeys?.filter((k) => !RECEIVABLE_TABLE_COLUMN_KEYS.includes(k));
    let receivableAlternativeNames = [];
    for (const receivableColumnKey of receivableColumnKeys) {
      if (receivableColumnKey === 'invoiceId') {
        receivableAlternativeNames.push('ID fakture');
      } else if (receivableColumnKey === 'documentDate') {
        receivableAlternativeNames.push('datum dokumenta');
      } else if (receivableColumnKey === 'customerName') {
        receivableAlternativeNames.push('Naziv kupca');
      } else if (receivableColumnKey === 'documentNumber') {
        receivableAlternativeNames.push('br. fakture');
      }
    }

    let keyReceivableArray = receivableColumnKeys;
    receivableNewColumnKeys = receivableColumnKeys?.map((item, index) => ({
      originalName: keyReceivableArray[index],
      alternativeName: receivableAlternativeNames[index],
    }));
  }

  let tableDataReceivable = [];
  if (receivableInvoices && receivableInvoices.length > 0) {
    tableDataReceivable = receivableInvoices.map((item) => {
      const documentDate = new Date(item.documentDate).toLocaleDateString('sr-RS');
      const dueDate = new Date(item.dueDate).toLocaleDateString('sr-RS');
      return { ...item, documentDate: documentDate, dueDate: dueDate };
    });
  }

  let tableDataPayable = [];
  if (payableInvoices && payableInvoices.length > 0) {
    tableDataPayable = payableInvoices.map((item) => {
      const documentDate = new Date(item.documentDate).toLocaleDateString('sr-RS');
      const dueDate = new Date(item.dueDate).toLocaleDateString('sr-RS');
      return { ...item, documentDate: documentDate, dueDate: dueDate };
    });
  }

  return (
    <div style={{ padding: '8px' }}>
      <Tabs>
        <TabPane tab='Arhiva poslatih faktura' key={0}>
          <ArchiveTable
            columnKeys={receivableNewColumnKeys}
            data={tableDataReceivable}
            recoverHandler={handleRecoverInvoice}
            type={'sales'}
          />
        </TabPane>
        <TabPane tab='Arhiva primljenih faktura' key={1}>
          <ArchiveTable
            columnKeys={payableNewColumnKeys}
            data={tableDataPayable}
            recoverHandler={handleRecoverInvoice}
            type={'purchase'}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Archive;
