import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

const ExportToExcel = ({ csvData, fileName, propsToRemove, users, header, format }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const removeProperties = (object, ...keys) =>
    Object.entries(object).reduce(
      (prev, [key, value]) => ({
        ...prev,
        ...(!keys.includes(key) && { [key]: value }),
      }),
      {},
    );

  const dataForExcel = async (data) => {
    let retData = [];
    if(users) {
      propsToRemove.push('companyName')
    }
    // Ako zelis da promenis imena polja u xlsx tabeli po imenu format propsa iz glavne komponente
    if (format === 'custom_name') {
      let promises = data.map(async (item) => {
        // item["email"] = item["userName"];  // dodeli vrednost starog polja novom
        // delete item['staro_ipepolja'];  // obrisi staro polje
        // retData.push(item[i]);  // push to new array
      });
      await Promise.all(promises);
    } else {
      retData = await data.map((item) => removeProperties(item, ...propsToRemove));
    }
    return retData;
  };



  const exportToCSV = async (csvData, fileName) => {
    const filtered = await dataForExcel(csvData);
    const ws = XLSX.utils.json_to_sheet(filtered, { header: header });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };



    

    if (users) {
      const newColumnNames = {
          firstName: 'Ime',
          lastName: 'Prezime',
          phone: 'Broj telefona',
          email: 'Email',
          address: 'Adresa',
          city: 'Grad',
          country: 'Država',
          jmbg: 'JMBG',
          position: 'Pozicija',
          bankAccount: 'Bankovni račun',
      };

      // Update column names in the worksheet
      for (const col in ws) {
          if (col.startsWith('!')) continue;
          ws[col].v = newColumnNames[ws[col].v] || ws[col].v;
      }

          const range = XLSX.utils.decode_range(ws['!ref']);
          for (let C = range.s.c; C <= range.e.c; ++C) {
          let maxCellLength = 5;
          for (let R = range.s.r + 1; R <= range.e.r; ++R) {
              const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
              if (cell && cell.v) {
                  const cellValueLength = String(cell.v).length;
                  if (cellValueLength > maxCellLength) {
                      maxCellLength = cellValueLength;
                  }
              }
          }
          ws[XLSX.utils.encode_col(C) + '1'] = { v: newColumnNames[ws[XLSX.utils.encode_col(C) + '1'].v] || ws[XLSX.utils.encode_col(C) + '1'].v };
          ws['!cols'] = ws['!cols'] || [];
          ws['!cols'][C] = { wch: maxCellLength + 2 }; // Add extra width for padding
          }

    } else if (!users) {
      const newColumnNames = {
          address: 'Adresa',
          city: 'Grad',
          zipCode: 'Poštanski broj',
          country: 'Država',
          contactPerson: 'Lice za kontakt',
          phone: 'Broj telefona',
          bankAccount: 'Bankovni račun',
          pib: 'pib',
          companyName: 'Naziv klijenta',
          position: 'Pozicija',
          pib: 'PIB',
          companyNumber: 'Matični broj',
          email: 'Email'
      };

      // Update column names in the worksheet
      for (const col in ws) {
          if (col.startsWith('!')) continue;
          ws[col].v = newColumnNames[ws[col].v] || ws[col].v;
      }

          const range = XLSX.utils.decode_range(ws['!ref']);
          for (let C = range.s.c; C <= range.e.c; ++C) {
          let maxCellLength = 10;
          for (let R = range.s.r + 1; R <= range.e.r; ++R) {
              const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
              if (cell && cell.v) {
                  const cellValueLength = String(cell.v).length;
                  if (cellValueLength > maxCellLength) {
                      maxCellLength = cellValueLength;
                  }
              }
          }
          ws[XLSX.utils.encode_col(C) + '1'] = { v: newColumnNames[ws[XLSX.utils.encode_col(C) + '1'].v] || ws[XLSX.utils.encode_col(C) + '1'].v };
          ws['!cols'] = ws['!cols'] || [];
          ws['!cols'][C] = { wch: maxCellLength + 2 }; // Add extra width for padding
          }
    }



    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: 'center' },
      fill: { fgColor: { rgb: 'FFFF00' } }, // Yellow background color for header
    };

    const dataStyle = {
      alignment: { horizontal: 'left' },
      // Add more styles as needed for data cells
    };

    // Apply styles to the worksheet
    ws['!header'] = headerStyle; // Apply header style
    ws['!rows'] = [{ hpx: 30 }]; 

    const dataRows = XLSX.utils.decode_range(ws['!ref']).e.r; // Get the number of data rows
    for (let i = 2; i <= dataRows; i++) {
        const row = XLSX.utils.encode_row(i);
        ws[row] = dataStyle;
    }

    const tableStyle = {
        showRowStripes: true,
        theme: 'TableStyleMedium9', // You can change the theme
    };

    // Apply the table style to the worksheet
    ws['!autofilter'] = { ref: ws['!ref'] }; // Enable autofilter
    ws['!table'] = tableStyle;
    
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      className='b2b-primary'
      size='small'
      style={{ marginLeft: '15px', marginRight: '20px' }}
      title='Export to Excel'
      onClick={(e) => exportToCSV(csvData, fileName)}
      disabled={(csvData && csvData.length === 0)}
    >
      <ExportOutlined /> Eksport za excel
    </Button>
  );
};

export default ExportToExcel;
