import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

const Header = (props) => {
  const { collapsedNav, handleCollapsedNav, setCollapsedNav } = props;
  return (
    <>
      <header className='header'>
        <Button type='primary' className='btn-collapse btn-menu' onClick={() => setCollapsedNav(!collapsedNav)}>
          <MenuOutlined />
          <span>Menu</span>
        </Button>

        <Link to='/admin' className='logo-mobile'>
          <div className='logo'>
            <img src='/dg-company.webp' alt='Universal logo' />
          </div>
        </Link>
      </header>

      <div className={`header-overlay ${collapsedNav ? 'isActive' : ''}`} onClick={() => handleCollapsedNav()} />
    </>
  );
};

export default Header;
