import React, { useContext, useState } from 'react';
import { Table, Popconfirm, Input, Button, Modal, Form } from 'antd';
import {
  DeleteFilled,
  DeleteOutlined,
  SearchOutlined,
  QuestionOutlined,
  FolderAddOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { UserContext } from '../../App';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const initialValues = {
  comment: undefined,
};

const InvoicesTable = ({
  data,
  deleteHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  openPdf,
  purchaseHandler,
  salesHandler,
  archiveHandler,
  acceptHandler,
  rejectHandler,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const user = useContext(UserContext);
  let searchInput;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordCopy, setRecordCopy] = useState();
  const [modalType, setModalType] = useState();
  const [inputContent, setInputContent] = useState('');

  const handleChange = (e) => {
    setInputContent(e.target.value);
  };

  const handleOk = () => {
    if (modalType === 'storn') {
      deleteHandler(recordCopy._id, recordCopy.status, form.getFieldsValue());
    } else if (modalType === 'accept') {
      acceptHandler(recordCopy._id, form.getFieldsValue());
    } else if (modalType === 'reject') {
      rejectHandler(recordCopy._id, form.getFieldsValue());
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za ' + t(`${dataIndex}`)}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 210, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button
            className='b2b-primary'
            onClick={() => handleReset(clearFilters)}
            // size='small'
            style={{ width: 90 }}
          >
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item.originalName,
    title: item?.alternativeName,
    dataIndex: ['title', 'category'].includes(item.originalName)
      ? [item.originalName, user.language ? user.language.selected.code : '']
      : item.originalName,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item.originalName)
        ? [item.originalName, user.language ? user.language.selected.code : '']
        : item.originalName,
    ),
  }));

  const getRowClassName = (record, index) => {
    // if (record.status === 'Seen') {
    //   return 'purchase_invoice_row_seen';
    // }
  };

  columns?.push({
    title: 'Options',
    width: '8%',
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'space-between',
          padding: '0 10px',
          alignItems: 'center',
          fontSize: '15px',
          width: '100%',
        }}
      >
        {record && record.documentNumber && (
          <Link to={`/admin/${record.type}-invoice-detail/${record._id}`}>
            <FilePdfOutlined title='Otvori pdf' style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
        )}
        {record.type === 'purchase' &&
        record.status !== 'Pregledano' &&
        record.status !== 'Odbijeno' &&
        record.status !== 'Prihvaćeno' ? (
          <div style={{ padding: '4px', cursor: 'pointer' }}>
            <QuestionCircleOutlined
              title='Proveri status'
              onClick={() => purchaseHandler(record._id)}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        ) : record.type === 'sales' &&
          record.status !== 'Pregledano' &&
          record.status !== 'Odbijeno' &&
          record.status !== 'Prihvaćeno' ? (
          <div style={{ padding: '4px', cursor: 'pointer' }}>
            <QuestionCircleOutlined
              title='Proveri status'
              onClick={() => salesHandler(record._id)}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        ) : (
          ''
        )}

        {record.status === 'Pregledano' && (
          <>
            <div style={{ margin: ' 0 10px', padding: '4px', cursor: 'pointer' }}>
              <CheckOutlined
                title='Prihvati fakturu'
                onClick={() => {
                  setIsModalOpen(true);
                  setRecordCopy(record);
                  setModalType('accept');
                }}
              />
            </div>
            <div style={{ margin: ' 0 10px', padding: '4px', cursor: 'pointer' }}>
              <CloseOutlined
                title='Odbij fakturu'
                onClick={() => {
                  setIsModalOpen(true);
                  setRecordCopy(record);
                  setModalType('reject');
                }}
              />
            </div>
          </>
        )}
        <div style={{ padding: '4px', cursor: 'pointer' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`Ovim ćete arhivirati fakturu`}
            onConfirm={() => archiveHandler(record._id)}
            okText='Ok'
            okType='default'
            cancelText='Odustani'
          >
            <FolderAddOutlined title='Arhiviraj fakturu' style={{ textDecoration: 'none', color: 'black' }} />
          </Popconfirm>
        </div>

        {/* {record.status === 'Draft' ? (
          <div style={{ margin: '2px', padding: '4px' }}>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={`Ovo će izbrisati fakturu`}
              onConfirm={() => deleteHandler(record._id, record.status, '')}
              okText='Ok'
              cancelText='Odustani'
            >
              <DeleteOutlined title={`Izbriši fakturu`} />
            </Popconfirm>
          </div>
        ) : (
          ''
        )} */}
      </div>
    ),
  });

  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        // rowClassName={(record, index) => getRowClassName(record, index)}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        okType='default'
        onCancel={handleCancel}
        style={{ top: 40 }}
        okButtonProps={modalType === 'reject' ? { disabled: !inputContent.length } : {}}
      >
        <h2 style={{ paddingTop: '10px' }}>
          {modalType === 'accept'
            ? 'Molimo Vas da potvrdite status e-fakture'
            : 'Molimo Vas da unesete razlog za odbijanje e-fakture'}
        </h2>
        <Form form={form} initialValues={initialValues}>
          {modalType === 'reject' && (
            <Form.Item name='comment'>
              <Input onChange={handleChange} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default InvoicesTable;
