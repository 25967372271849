import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import { Input, Button, Form, Card, Tabs, notification, Modal } from 'antd';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import moment from 'moment';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

const { TabPane } = Tabs;

const initialValues = {
  commentRejection: undefined,
};

const InvoiceDetail = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [invoice, fetchInvoice] = useAxios('', [], currentuser.data.token, 'get');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [inputContent, setInputContent] = useState('');
  const [embedStyle, setEmbedStyle] = useState({});
  const [embedContainerStyle, setEmbedContainerStyle] = useState({});
  const [isPurchased, setIsPurchased] = useState(true);

  const { _id } = props.match.params;

  let vats =
    invoice?.data?.vats?.length > 0 &&
    _.mapKeys(invoice?.data?.vats[0], function (value, key) {
      return 'v' + key;
    });
  let roundedVats = _.mapValues(vats, function (value) {
    return _.round(value, 1);
  });

  useEffect(() => {
    if (window.location.pathname.includes('sales')) {
      fetchInvoice(`${SERVER_URL}/sales-pdf/${_id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      hideTable();
    } else if (window.location.pathname.includes('purchase')) {
      fetchInvoice(`${SERVER_URL}/purchase-pdf/${_id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    }
  }, [fetchInvoice]);

  const hideTable = () => {
    if (window.location.pathname.includes('purchase')) {
      setIsPurchased(true);
    } else {
      setIsPurchased(false);
    }
  };

  useEffect(() => {
    if (invoice?.data) {
      form.setFieldsValue({
        customerName: invoice?.data?.customerName,
        supplierName: invoice?.data?.supplierName,
        documentType: invoice?.data?.documentType,
        documentNumber: invoice?.data?.documentNumber,
        documentDate: moment(invoice?.data?.documentDate),
        supplyDate: moment(invoice?.data?.supplyDate),
        dueDate: moment(invoice?.data?.dueDate),
        interestArrears: invoice?.data?.interestArrears,
        poNumber: invoice?.data?.poNumber,
        customerReference: invoice?.data?.customerReference,
        supplierReference: invoice?.data?.supplierReference,
        paymentReference: invoice?.data?.paymentReference,
        comment: invoice?.data?.comment,
        totalDiscounts: invoice?.data?.totalDiscounts,
        totalVatExcl: invoice?.data?.totalVatExcl,
        total: invoice?.data?.total,
        prepaidAmount: invoice?.data?.prepaidAmount,
        openBalance: invoice?.data?.openBalance,
        products: invoice?.data?.products,
        pdf: invoice?.data?.pdf,
      });
    }
  }, [invoice]);

  const sendInvoice = async () => {
    try {
      await Axios.post(`${SERVER_URL}/postUbl`, invoice?.data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Faktura je poslata.`,
        placement: 'bottomRight',
      });
      history.push('/admin/accounts-receivable');
    } catch (error) {
      if (error.response.data) {
        notification.error({
          message: error.response.data,
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: `Problem sa slanjem fakture. Pokušajte kasnije.`,
          placement: 'bottomRight',
        });
      }
    }
  };

  const acceptInvoice = async (comment) => {
    try {
      await Axios.post(`${SERVER_URL}/accept-purchase/${_id}`, comment, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Faktura je prihvaćena.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.go(-1);
      }, 1000);
    } catch (err) {
      notification.error({
        message: 'PProblem sa prihvatanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const rejectInvoice = async (formValues) => {
    const comment = { comment: formValues.commentRejection };
    try {
      await Axios.post(`${SERVER_URL}/reject-purchase/${_id}`, comment, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Faktura je odbijena.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.go(-1);
      }, 1000);
    } catch (err) {
      notification.error({
        message: 'Problem sa odbijanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const handleOk = async () => {
    if (modalType === 'storn') {
      // Implement storn logic if needed
    } else if (modalType === 'accept') {
      try {
        await acceptInvoice(form.getFieldsValue());
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error accepting invoice:', error);
      }
    } else if (modalType === 'reject') {
      try {
        await rejectInvoice(form.getFieldsValue());
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error rejecting invoice:', error);
      }
    }
  };

  const handleChange = (e) => {
    setInputContent(e.target.value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleBack = () => {
    setTimeout(() => {
      history.go(-1);
    }, 500);
  };

  const downloadInvoice = () => {
    const route = invoice?.data?.type === 'sales' ? 'view-sales' : 'view-purchase';
    const companyName = invoice?.data?.supplierName ? invoice?.data?.supplierName : invoice?.data?.customerName;
    fetch(`${SERVER_URL}/${route}/${_id}`).then((response) => {
      response.blob().then((blob) => {
        const formatedDate = dayjs(new Date(invoice?.data?.documentDate)).format('DD.MM.YYYY');
        let alink = document.createElement('a');
        alink.href = `data:application/pdf;base64,${invoice?.data?.pdf}`;
        alink.download = `${formatedDate}-${companyName}.pdf`;
        alink.click();
      });
    });
  };

  const printInvoice = () => {
    const route = invoice?.data?.type === 'sales' ? 'view-sales' : 'view-purchase';
    fetch(`${SERVER_URL}/${route}/${_id}`)
      .then((response) => response.blob())
      .then((blob) => {
        const byteCharacters = atob(invoice?.data?.pdf);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });

        // Create a URL for the PDF blob
        const pdfObjectUrl = URL.createObjectURL(pdfBlob);

        // Open a new window or tab and set its content to the PDF blob
        const printWindow = window.open(pdfObjectUrl, '_blank');

        // Wait for the PDF to load, then trigger the print dialog
        printWindow.onload = () => {
          printWindow.print();
          URL.revokeObjectURL(pdfObjectUrl); // Release the object URL after printing
        };
      });
  };

  return (
    <div style={{ padding: '8px', height: '100%' }}>
      {/* {invoice?.data?.status === 'Draft' && (
        <div className='actions-block'>
          <Link to={`/admin/edit-invoice/${_id}`}>
            <Button
              className='b2b-primary'
              onClick={() => {
                console.log('edit');
              }}
            >
              Edituj fakturu
            </Button>
          </Link>
          <Button className='b2b-primary' style={{ marginLeft: '10px' }} onClick={sendInvoice}>
            Pošalji fakturu
          </Button>
        </div>
      )} */}
      <div style={{ width: '100%', height: '100%', justifyContent: 'space-between' }}>
        {/*<Card if title='DETALJI FAKTURE' bordered={false} style={isPurchased ? { display: 'none' } : null}>*/}
        {/*  <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} labelAlign='left' layout='horizontal' form={form}>*/}
        {/*    <Tabs>*/}
        {/*      <TabPane tab='Details' key={1}>*/}
        {/*        {window.location.pathname.includes('sales') && (*/}
        {/*          <Form.Item label='Klijent' name='customerName'>*/}
        {/*            <Input disabled />*/}
        {/*          </Form.Item>*/}
        {/*        )}*/}
        {/*        {window.location.pathname.includes('purchase') && (*/}
        {/*          <Form.Item label='Dobavljač' name='supplierName'>*/}
        {/*            <Input disabled />*/}
        {/*          </Form.Item>*/}
        {/*        )}*/}
        {/*        /!* Rest of the form items for details *!/*/}
        {/*      </TabPane>*/}
        {/*      <TabPane tab='Linije fakture' key={2}>*/}
        {/*        /!* Form.List and invoice detail lines *!/*/}
        {/*      </TabPane>*/}
        {/*      <TabPane tab='Attachments' key={3}>*/}
        {/*        /!* Attachments section *!/*/}
        {/*      </TabPane>*/}
        {/*    </Tabs>*/}
        {/*  </Form>*/}
        {/*</Card>*/}

        <div>
          {/* <div style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '6px' }}>
              <Button
                title='Prihvati fakturu'
                style={{ backgroundColor: 'white', borderStyle: 'solid', borderLeftColor: 'black', borderBottomColor: 'black', borderRightColor: 'black', borderTopColor: 'black', paddingLeft: '8px' }}
                onClick={() => {
                  setIsModalOpen(true);
                  setModalType('accept');
                }}
              >
                Prihvati
              </Button>
            </div> */}
          {/* <div style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '5px' }}>
              <Button
                title='Odbij fakturu'
                style={{ backgroundColor: 'white', borderStyle: 'solid', borderLeftColor: 'black', borderBottomColor: 'black', borderRightColor: 'black', borderTopColor: 'black', marginTop: '5px', paddingLeft: '8px' }}
                onClick={() => {
                  setIsModalOpen(true);
                  setModalType('reject');
                }}
              >
                Odbij
              </Button>
            </div> */}
          <div
            style={{
              cursor: 'pointer',
              marginTop: '5px',
              display: 'flex',
              justifyContent: 'end',
              gap: '10px',
            }}
          >
            <Button className='b2b-primary' onClick={downloadInvoice}>
              Preuzmi
            </Button>
            <Button title='Štampaj fakturu' className='b2b-primary' onClick={printInvoice}>
              Štampaj
            </Button>
            <Button className='b2b-primary' onClick={handleBack}>
              NAZAD
            </Button>
          </div>
        </div>

        {/* <Modal
          open={isModalOpen}
          onOk={handleOk}
          okType='default'
          onCancel={handleCancel}
          style={{ top: 40 }}
          okButtonProps={modalType === 'reject' ? { disabled: !inputContent.length } : {}}
          cancelText='Odustani'
        >
          <h2 style={{ paddingTop: '10px' }}>
            {modalType === 'accept'
              ? 'Molimo Vas da potvrdite status e-fakture'
              : 'Molimo Vas da unesete razlog za odbijanje e-fakture'}
          </h2>
          <Form
            form={form}
            // initialValues={initialValues}
            name='control-hooks'
          >
            {modalType === 'reject' && (
              <Form.Item name='commentRejection' label='Razlog'>
                <Input onChange={handleChange} />
              </Form.Item>
            )}
          </Form>
        </Modal> */}
        {invoice.data.pdf && (
          <div
            style={{
              height: '900px',
              border: '1px solid #ddd',
              overflow: 'hidden',
              margin: '10px auto',
            }}
          >
            <object
              data={`data:application/pdf;base64,${invoice.data.pdf}`}
              type='application/pdf'
              width='100%'
              height='100%'
              style={embedStyle}
              aria-label='invoice'
            >
              <embed
                src={`data:application/pdf;base64,${invoice.data.pdf}`}
                type='application/pdf'
                width='100%'
                height='100%'
                style={embedStyle}
                aria-label='invoice'
              />
            </object>
            <Button
              onClick={() => {
                const pdfWindow = window.open('');
                pdfWindow.document.write(
                  `<iframe width='100%' height='100%' src='data:application/pdf;base64,${invoice.data.pdf}'></iframe>`,
                );
              }}
              style={{ width: '100%', marginTop: '8px' }}
              type='primary'
            >
              Otvori u novom tabu
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceDetail;
