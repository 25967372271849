import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Select, Result, Radio, notification, Modal, Row, Col } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const tailLayout = {
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  companyName: undefined,
  phone: '',
  address: undefined,
  city: undefined,
  zipCode: undefined,
  country: undefined,
  contactPerson: [],
  taxId: undefined,
  bankAccount: undefined,
  pib: undefined,
  companyNumber: undefined,
  note: undefined,
  attachments: [],
};

const ClientRegularForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile,
  fullWidth,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  // const [legalType, setLegalType] = useState(data ? data.legalType : 'PRIVATE');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  let client = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [promptName] = useState(); //izbrisano setPromptName
  const [deletePop, setDeletePop] = useState();
  const [counter, setCounter] = useState(isNew ? 1 : client?.contactPerson?.length);

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
    setShowModal(!showModal);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Problem sa bazom. Pokušajte kasnije.',
      placement: 'bottomRight',
    });
  };

  const deleteClientHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Kompanija je obrisana.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const handleDeleteContact = (index) => {
    // Create a copy of the current state
    const updatedCounter = [...counter];
    // Remove the item at the specified index
    updatedCounter.splice(index, 1);
    // Set the state with the updated array
    setCounter(updatedCounter);
  };

  return (
    <>
      <div className='dashboard'>
        <div className='panel panel-body'>
          {!result && (
            <Form
              {...layout}
              name='basic'
              initialValues={client}
              onFinish={(values) => onFinish(values, isNew)}
              onFinishFailed={onFinishFailed}
              layout='horizontal'
              form={form}
            >
              <div>
                <div>
                  <Form.Item
                    name='companyName'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo da unesete naziv kompanije!',
                      },
                    ]}
                  >
                    <Input placeholder='Naziv kompanije' />
                  </Form.Item>

                  <Form.Item
                    name='address'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo da unesete adresu kompanije!',
                      },
                    ]}
                  >
                    <Input placeholder='Adresa' />
                  </Form.Item>
                  <Form.Item
                    name='city'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo unesite grad!',
                      },
                    ]}
                  >
                    <Input placeholder='Grad' />
                  </Form.Item>
                  <Form.Item
                    name='zipCode'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo da unesete poštanski kod!',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Poštanski kod'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo da unesete državu!',
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name='country'>
                    <Input placeholder='Država' />
                  </Form.Item>

                  <Form.Item name='phone'>
                    <Input placeholder='Telefon' />
                  </Form.Item>
                  <Form.Item
                    name='email'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo unesite e-mail!',
                      },
                    ]}
                  >
                    <Input placeholder='Email' />
                  </Form.Item>
                  <Form.Item name='bankAccount'>
                    <Input placeholder='Broj bankovnog računa' />
                  </Form.Item>
                  <Form.Item
                    name='pib'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo da unesete PIB!',
                      },
                    ]}
                  >
                    <Input placeholder='PIB' />
                  </Form.Item>
                  <Form.Item
                    name='companyNumber'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo da unesete matični broj kompanije!',
                      },
                    ]}
                  >
                    <Input placeholder='Matični broj' />
                  </Form.Item>

                  <Form.Item
                    name='apiKey'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo da unesete api kod!',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Api kod'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo da unesete api kod!',
                        },
                      ]}
                    />
                  </Form.Item>
                  {/*// TODO check if this is needed*/}
                  {/*{!isNew && (*/}
                  {/*    <Form.Item*/}
                  {/*        /*label='Status'*/}
                  {/*        name='status'*/}
                  {/*        rules={[*/}
                  {/*            {*/}
                  {/*                required: true,*/}
                  {/*                message: 'Molimo da izaberete status!',*/}
                  {/*            },*/}
                  {/*        ]}*/}
                  {/*    >*/}
                  {/*        <Select getPopupContainer={(trigger) => trigger.parentNode}>*/}
                  {/*            <Select.Option value='ACTIVE'>ACTIVE</Select.Option>*/}
                  {/*            <Select.Option value='SUSPENDED'>SUSPENDED</Select.Option>*/}
                  {/*            <Select.Option value='DELETED'>DELETED</Select.Option>*/}
                  {/*            <Select.Option value='WAITING_FOR_ACTIVATION'>WAITING_FOR_ACTIVATION</Select.Option>*/}
                  {/*        </Select>*/}
                  {/*    </Form.Item>*/}
                  {/*)}*/}
                </div>
              </div>

              {/* <Form.List
                name='contactPerson'
                // initialValue={
                //   data?.contactPerson
                //     ? data?.contactPerson
                //     : [
                //         {
                //           contactEmail: '',
                //           contactPerson: '',
                //           contactPhone: '',
                //           contactPosition: '',
                //         },
                //       ]
                // }
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '10px',
                            fontWeight: '600',
                          }}
                        >
                          <span>Kontakt osoba:</span>
                          <Button
                            type='text'
                            style={{
                              border: 'none',
                              background: 'transparent',
                              boxShadow: 'none',
                              display: 'flex',
                              justifyContent: 'end',
                              alignItems: 'center',
                            }}
                            onClick={() => remove(field.name)}
                          >
                            <DeleteOutlined />
                          </Button>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div style={{ display: 'flex', gap: '15px' }}>
                            <Form.Item name={[field.name, 'contactPerson']}>
                              <Input placeholder='Ime' />
                            </Form.Item>

                            <Form.Item name={[field.name, 'contactEmail']}>
                              <Input placeholder='Email' />
                            </Form.Item>
                          </div>
                          <div style={{ display: 'flex', gap: '15px' }}>
                            <Form.Item name={[field.name, 'contactPhone']}>
                              <Input placeholder='Telefon' type='number' />
                            </Form.Item>

                            <Form.Item name={[field.name, 'contactPosition']}>
                              <Input placeholder='Pozicija' />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div
                      className='add-contact-button'
                      style={{ display: 'flex', justifyContent: 'end', marginBottom: '40px' }}
                    >
                      <div
                        className='b2b-primary kontakt-button'
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => add()}
                      >
                        <span>Dodaj kont. osobu</span>
                      </div>
                    </div>
                  </>
                )}
              </Form.List> */}

              <Form.Item {...tailLayout}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                  <Button className='b2b-primary' htmlType='submit' style={{ float: 'right', width: '50%' }}>
                    {data ? 'Sačuvaj promene' : 'Dodaj kompaniju'}
                  </Button>
                  <Button
                    className='b2b-secondary'
                    style={{ float: 'right', width: '50%' }}
                    onClick={() => setShowModal(!showModal)}
                  >
                    Poništi
                  </Button>
                </div>
              </Form.Item>
            </Form>
          )}
          {result && (
            <Result
              title='Klijent je kreiran'
              extra={
                <Button
                  type='primary'
                  key='console'
                  onClick={() => {
                    setResult(false);
                    window.location.reload();
                  }}
                >
                  Ok
                </Button>
              }
            />
          )}
          <Modal
            className='deleteModal'
            centered={true}
            open={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteClientHandler(client._id)}
            okText='Yes'
            cancelText='No'
          >
            <p>Da li ste sigurni da želite da uradite brisanje</p>
            {promptName && <p>{promptName.toUpperCase()}?</p>}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ClientRegularForm;
