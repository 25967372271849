import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { ClientRegularForm } from '../../components/forms';

const EditRegularClient = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [client, setClient] = useState([]);
  const history = useHistory();
  const clientId = props.match.params.dataId;
  console.log('PROPS', props);
  console.log('clientId', clientId);

  useEffect(() => {
    if (clientId) {
      console.log('clientId', clientId);
      fetchClient(clientId);
    }
  }, [clientId]);

  const fetchClient = async (id) => {
    try {
      const fetchedData = await Axios.get(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      console.log('fetchedData', fetchedData);
      setClient(fetchedData);
    } catch (err) {
      notification.error({
        message: 'Problem sa bazom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const updateClient = async (data) => {
    let isError = false;
    if (data.companyName.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite naziv kompanije!',
        placement: 'bottomRight',
      });
    }
    if (data.address.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite adresu kompanije!!',
        placement: 'bottomRight',
      });
    }
    if (data.city.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite grad!',
        placement: 'bottomRight',
      });
    }
    if (data.zipCode.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite poštanski broj!',
        placement: 'bottomRight',
      });
    }
    if (data.country.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite zemlju!',
        placement: 'bottomRight',
      });
    }
    if (data.pib.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite PIB!',
        placement: 'bottomRight',
      });
    }
    if (data.companyNumber.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite matični broj!',
        placement: 'bottomRight',
      });
    }
    if (!isError) {
      console.log('usaoooo 11111');
      try {
        await Axios.put(
          `${SERVER_URL}/clients/${clientId}`,
          { ...data },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        notification.success({
          message: 'Kompanija je ažurirana.',
          placement: 'bottomRight',
        });
        // history.push('/admin');
        // history.push('/admin/users');
      } catch (err) {
        notification.error({
          message: 'Problem sa ažuriranjem. Pokušajte kasnije.',
          placement: 'bottomRight',
        });
      }
    }
  };

  // const createClient = async (data) => {
  //     try {
  //         let isError = false;
  //         if (data.firstName.trim() === '') {
  //             isError = true;
  //             notification.error({
  //                 message: 'Invalid first name!',
  //                 placement: 'bottomRight',
  //             });
  //         }
  //         if (data.lastName.trim() === '') {
  //             isError = true;
  //             notification.error({
  //                 message: 'Invalid last name!',
  //                 placement: 'bottomRight',
  //             });
  //         }
  //         if (data.password.includes(' ')) {
  //             isError = true;
  //             notification.error({
  //                 message: 'Invalid password!',
  //                 placement: 'bottomRight',
  //             });
  //         }
  //         if (data.city.trim() === '') {
  //             isError = true;
  //             notification.error({
  //                 message: 'Invalid city!',
  //                 placement: 'bottomRight',
  //             });
  //         }
  //         if (data.country.trim() === '') {
  //             isError = true;
  //             notification.error({
  //                 message: 'Invalid country!',
  //                 placement: 'bottomRight',
  //             });
  //         }
  //         if (data.companyName && data.companyName.trim() === '') {
  //             isError = true;
  //             notification.error({
  //                 message: 'Invalid company name!',
  //                 placement: 'bottomRight',
  //             });
  //         }
  //         if (data.taxId && data.taxId.trim() === '') {
  //             isError = true;
  //             notification.error({
  //                 message: 'Invalid tax Id!',
  //                 placement: 'bottomRight',
  //             });
  //         }
  //         if (isError === false) {
  //             await Axios.post(`${SERVER_URL}/clients`, { ...data });
  //             notification.success({
  //                 message: 'Klijent je kreiran.',
  //                 placement: 'bottomRight',
  //             });
  //             setResult(true);
  //
  //         }
  //     } catch (err) {
  //         notification.error({
  //             message:
  //                 err.response.data && err.response.data.message
  //                     ? err.response.data.message
  //                     : 'Problem kreiranjem klijenta. Pokušajte kasnije.',
  //             placement: 'bottomRight',
  //         });
  //     }
  // };

  return (
    <div>
      {/* <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>All users</Button>
        </Link>
      </div> */}

      <div style={{ textAlign: 'center' }}>
        {clientId && client.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {clientId && !client.isLoading && !client.isError && client.data && (
          <ClientRegularForm
            data={client.data}
            updateHandler={updateClient}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            fullWidth={true}
          />
        )}
        {clientId && !client.isLoading && client.isError && <h2 style={{ marginTop: '5rem' }}>Nešto nije uredu :(</h2>}
        {/*{!clientId && client && !client.data && (*/}
        {/*    <ClientRegularForm*/}
        {/*        data={null}*/}
        {/*        createHandler={createClient}*/}
        {/*        result={result}*/}
        {/*        setResult={setResult}*/}
        {/*        SERVER_URL={SERVER_URL}*/}
        {/*        token={currentuser.data.token}*/}
        {/*    />*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default EditRegularClient;
