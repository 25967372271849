import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Select } from 'antd';
import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, collapsedNav, handleCollapsedNav } = props;
  const currentuser = useContext(UserContext);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <Sider
      breakpoint='lg'
      className={`gl_sidebar ${collapsedNav ? 'isActive' : ''}`}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      width={220}
    >
      <button className='btn-close btn-close-menu' onClick={handleCollapsedNav}>
        <span className='icon'>&#10005;</span>
      </button>

      <Link to='/admin' className='logo-desktop' onClick={handleCollapsedNav}>
        <div className='logo'>
          <img src='/dg-company.webp' style={{ maxHeight: '49px', width: '104px' }} alt='Universal api' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {ROUTES.map((route) => {
          return (
            <SubMenu
              className='submenu'
              key={route.label.toLowerCase()}
              title={isCollapsed ? <route.icon /> : <span>{route.label}</span>}
            >
              {route.children
                .filter((item) => item.showInMenu)
                .map((item) => (
                  <Menu.Item key={item.path} onClick={handleCollapsedNav}>
                    <Link to={item.path}>{item.label}</Link>
                  </Menu.Item>
                ))}
            </SubMenu>
          );
        })}
      </Menu>
      <div className='sider-profile-controls'>
        <div className='account' style={{ display: 'flex', justifyContent: 'end' }}>
          {/* <Link
            to={`/admin/view-user/${currentuser && currentuser?.data?.id}`}
            className='sider-profile-controls-profile'
            onClick={handleCollapsedNav}
          >
            <UserOutlined title='Detalji o korisniku' />
          </Link> */}

          <LogoutOutlined onClick={handleSignOutSubmit} title='Logout' className='sider-profile-controls-logout' />
        </div>

        {/* <Select
            className='sider-profile-controls sider-profile-controls-language'
            value={currentuser.language && currentuser.language.selected.name}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(lang) =>
              currentuser.setLanguage({
                ...currentuser.language,
                selected: currentuser.language.list.find((l) => l.name === lang),
              })
            }
          >
            {currentuser.language &&
              currentuser.language.list.map((lang) => (
                <Select.Option value={lang.name} key={lang.name}>
                  {lang.name}
                </Select.Option>
              ))}
          </Select> */}
      </div>
    </Sider>
  );
};
export default NavMenu;
