import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { message } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from './config';
import Layout from './components/base/Layout';
import Login from './pages/Login';
import './App.scss';
import { Route } from 'react-router-dom';
import ForgotPassword from './pages/users/ForgotPassword';
import ResetPassword from './pages/users/ResetPassword';
import EmailVerification from './pages/users/EmailVerification';

export const UserContext = createContext(null);

const handleLogin = async (user) => {
  try {
    const userResponse = await Axios.post(
      `${SERVER_URL}/login`,
      { email: user.email, password: user.password },
      { withCredentials: false },
    );
    if (
      userResponse.data.message === 'Auth successful' &&
      (userResponse.data.role.includes('admin') || userResponse.data.role.includes('user'))
    ) {
      sessionStorage.setItem('user', JSON.stringify(userResponse.data));
      window.location.reload();
    } else if (
      userResponse.data.message === 'Auth successful' &&
      (!userResponse.data.role.includes('admin') || !userResponse.data.role.includes('user'))
    ) {
      return message.info('Nije vam omogućen pristup platformi!', 3);
    }
  } catch (err) {
    console.log('\n Login error = ', err.response.data.message);
    return message.warning(err.response.data.message, 3);
  }
};

let user = sessionStorage.getItem('user');
if (user) user = JSON.parse(user);

console.log('USER', user);

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [language, setLanguage] = useState();

  const purchaseScript = async () => {
    try {
      const company = user?.company;
      const response = await Axios.post(
        `${SERVER_URL}/purchase-script`,
        {
          // company
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      return response.data;
    } catch (error) {
      console.log('error', error.message);
    }
  };

  const salesStatusScript = async () => {
    try {
      const company = user?.company;
      const response = await Axios.post(`${SERVER_URL}/sales-status-script`, {
        company,
      });
      return response.data;
    } catch (error) {
      console.log('error', error.message);
    }
  };

  // useEffect(() => {
  //   if (user) purchaseScript();
  //   // salesStatusScript();
  // }, []);

  useEffect(() => {
    if (user && new Date() > new Date(user.expires)) {
      console.log('Session expired!');
      setLoggedIn(false);
    } else if (user === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn, user]);

  useEffect(() => {
    Axios.get(`${SERVER_URL}/languages-selected`)
      .then((resp) => {
        const defaultLanguage = resp.data.find((l) => l.default);
        setLanguage({ selected: defaultLanguage, list: resp.data, default: defaultLanguage });
      })
      .catch((err) => console.log(err.response ? err.response.data.message : err.message));
  }, [setLanguage]);

  return (
    <UserContext.Provider value={{ setLoggedIn, isLoggedIn, handleLogin, data: user, language, setLanguage }}>
      <Router>
        {isLoggedIn && <Layout />}
        {!isLoggedIn && <Route exact path='/admin' component={Login} />}
        {!isLoggedIn && <Route exact path='/login' component={Login} />}
        {<Route exact path='/forgot-password' component={ForgotPassword} />}
        {<Route exact path='/password-reset/:passwordToken' component={ResetPassword} />}
        {<Route exact path='/email-verification/:emailToken' component={EmailVerification} />}
      </Router>
    </UserContext.Provider>
  );
};

export default App;
