import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from '../../config';
import ProtectedRoute from './ProtectedRoute';
import Login from '../../pages/Login';
import Home from '../../pages/Home';
import ForgotPassword from '../../pages/users/ForgotPassword';
import ResetPassword from '../../pages/users/ResetPassword';
import EmailVerification from '../../pages/users/EmailVerification';

const Routes = () => {
  const user = useContext(UserContext);

  return (
    <Switch>
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route exact path='/password-reset/:passwordToken' component={ResetPassword} />
      <Route exact path='/email-verification/:emailToken' component={EmailVerification} />
      <ProtectedRoute user={user.data} exact path='/admin' component={Home} allowed={['admin', 'user']} />
      {/* routes from routeConfig file */}
      {ROUTES.map((route) =>
        route.children.map((item, i) => <ProtectedRoute exact user={user.data} key={`R_${i}`} 
        path={item.path} component={item.component} allowed={item.allowed} />),
      )}
      <Route exact path='/login' render={() => (user && user.isLoggedIn ? <Redirect to='/admin' /> : <Login />)} />
      <Route render={() => <Redirect to='/admin' />} />
    </Switch>
  );
};

export default Routes;
