import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { notification, Drawer /* , Button */ } from 'antd';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { ClientRegularForm } from '../../components/forms';
import useAxios from '../../hooks/useAxios';

const EditClientRegularModal = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [client, setClient] = useState(undefined);
  const [singleClient, fetchSingleClient] = useAxios('', null, currentuser.data.token, 'get');

  const clientId = props.clientID;
  const { showModal, setShowModal } = props;

  useEffect(() => {
    if (clientId) {
      fetchSingleClient(`${SERVER_URL}/clients/${clientId}`, []);
    }
  }, [clientId, fetchSingleClient]);

  const updateClient = async (data) => {
    let isError = false;
    if (data.companyName.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite naziv kompanije!',
        placement: 'bottomRight',
      });
    }
    if (data.address.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite adresu kompanije!!',
        placement: 'bottomRight',
      });
    }
    if (data.city.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite grad!',
        placement: 'bottomRight',
      });
    }
    if (data.zipCode.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite poštanski broj!',
        placement: 'bottomRight',
      });
    }
    if (data.country.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite zemlju!',
        placement: 'bottomRight',
      });
    }
    if (data.pib.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite PIB!',
        placement: 'bottomRight',
      });
    }
    if (data.companyNumber.trim() === '') {
      isError = true;
      notification.error({
        message: 'Unesite matični broj!',
        placement: 'bottomRight',
      });
    }
    if (!isError) {
      try {
        await Axios.put(
          `${SERVER_URL}/clients/${clientId}`,
          { ...data },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        notification.success({
          message: 'Kompanija je ažurirana.',
          placement: 'bottomRight',
        });
        // history.push('/admin');
        // history.push('/admin/users');
      } catch (err) {
        notification.error({
          message: 'Problem sa ažuriranjem. Pokušajte kasnije.',
          placement: 'bottomRight',
        });
      }
    }
  };

  const createClient = async (data) => {
    try {
      let isError = false;
      if (data.companyName.trim() === '') {
        isError = true;
        notification.error({
          message: 'Unesite naziv kompanije!',
          placement: 'bottomRight',
        });
      }
      if (data.address.trim() === '') {
        isError = true;
        notification.error({
          message: 'Unesite adresu kompanije!!',
          placement: 'bottomRight',
        });
      }
      if (data.city.trim() === '') {
        isError = true;
        notification.error({
          message: 'Unesite grad!',
          placement: 'bottomRight',
        });
      }
      if (data.zipCode.trim() === '') {
        isError = true;
        notification.error({
          message: 'Unesite poštanski broj!',
          placement: 'bottomRight',
        });
      }
      if (data.country.trim() === '') {
        isError = true;
        notification.error({
          message: 'Unesite zemlju!',
          placement: 'bottomRight',
        });
      }
      if (data.pib.trim() === '') {
        isError = true;
        notification.error({
          message: 'Unesite PIB!',
          placement: 'bottomRight',
        });
      }
      if (data.companyNumber.trim() === '') {
        isError = true;
        notification.error({
          message: 'Unesite matični broj!',
          placement: 'bottomRight',
        });
      }
      if (isError === false) {
        console.log('USAOOOOOO');
        await Axios.post(
          `${SERVER_URL}/clients`,
          { ...data },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        notification.success({
          message: 'Kompanija je kreirana.',
          placement: 'bottomRight',
        });
        setResult(true);
      }
    } catch (err) {
      console.log('ERR', err);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa kreiranjem kompanije. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => {
    props.setShowModal(false);
  };

  return (
    <>
      <div style={{ textAlign: 'center', height: '100%' }}>
        {clientId && !singleClient.isLoading && !singleClient.isError && singleClient.data && (
          <Drawer title={'Ažuriranje kompanije'} placement='right' onClose={onClose} open={props.showModal} width={420}>
            <ClientRegularForm
              data={singleClient.data}
              updateHandler={updateClient}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
              profile={props.profile}
            />
          </Drawer>
        )}
        {/*{clientId && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}*/}
        {!clientId && (
          <Drawer title={'Dodaj kompaniju'} placement='right' onClose={onClose} open={props.showModal} width={420}>
            <ClientRegularForm
              data={null}
              createHandler={createClient}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </Drawer>
        )}
        {/* </div> */}
      </div>
    </>
  );
};

export default EditClientRegularModal;
