import React, { useState } from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { Link, useParams } from 'react-router-dom';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(/login-bcg.jpg)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.8)',
};

const companyLogoStyle = {
  maxWidth: '160px',
  marginBottom: '30px',
};

const ResetPassword = () => {
  // const [password, setPassword] = useState('');
  let { passwordToken } = useParams();

  console.log('token', passwordToken);
  console.log('SERVER_URL', SERVER_URL);
  const onFinish = async (values) => {
    try {
      const ResetPasswordLink = await Axios.post(
        `${SERVER_URL}/password-reset/${passwordToken}`,
        { password: values.password },
        { withCredentials: false },
      );
      if (ResetPasswordLink.data.message) {
        notification.success({
          message: ResetPasswordLink.data.message,
          // message: 'Password succesfully reseted.',
          placement: 'bottomRight',
        });
      }
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='log' style={backgroundStyle}>
      <div className='card-wrapper'>
        <Card className='login-header' bordered={false} style={loginCardStyle}>
          <img style={companyLogoStyle} className='login-logo' src='/dg-company.webp' alt='Universal Logo' />
          <Meta title='Unesite novu šifru' />
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item name='password' rules={[{ required: true, message: 'Molimo unesite šifru!' }]}>
              <Input placeholder='Password' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button type='primary' style={{ width: '100%' }} htmlType='submit'>
                Reset
              </Button>
            </Form.Item>

            <Form.Item {...tailLayout} style={{ paddingTop: '30px' }}>
              <Link to='/login' className='link-forgot-password'>
                Prijava
              </Link>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
